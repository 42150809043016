import { generateAnimation } from "./animationFunctions";
import { centerItems, styleBackground } from "./styleFunctions";

export const Button = ({
  name,
  text,
  height,
  width,
  backgroundInfo,
  style,
  onClick,
  horizontalAlignment,
  fontSize,
}) => {
  const setBackground = () => {
    return name.includes("window-selection-button")
      ? {}
      : { color: "white", ...styleBackground(backgroundInfo) };
  };
  return (
    <button
      className={name}
      style={{
        boxShadow:
          style === "floating" ? "rgba(0, 0, 100, 0.2) 0px 0px 15px 1px" : "",
        borderRadius: "5px 0px 0px 5px",
        height: height,
        width: width,
        textShadow: "0px 0px 10px rgba(0 0 0 / 0.5)",
        ...setBackground(),
        ...centerItems,
      }}
      onClick={onClick}
    >
      <div
        className="text container"
        style={{
          display: "table",
          height: "100%",
          width: "100%",
        }}
      >
        <p
          style={{
            display: "table-cell",
            textAlign: horizontalAlignment,
            verticalAlign: "middle",
            fontSize: fontSize,
          }}
        >
          {text}
        </p>
      </div>
    </button>
  );
}; //Styles = Floating, flat, neumorphism, also this should be for multiple buttons as well with a spacer, that's what the content should be, an array of buttons
