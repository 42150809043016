import { ContactForm } from "../Content/Contact/ContactForm";
import { VideoPage } from "../../components/layout/sections/VideoPage";
import contactLogo from "../../assets/images/Contact_Logo.png";
import contactSlogan from "../../assets/images/Contact_Slogan.png";
import contactVideo from "../../assets/videos/Thank You for Visiting.mp4";
import { contactGradient } from "../../components/sections/content/styles";
import icon from "../../assets/images/Contact_Icon_Large.png";
import { ContactContent } from "../Content/Contact/ContactContent";

const headerHeight = window.innerHeight;
const mainHeight = 1000;
const contactTitle = "Thank You for Visiting";
const contactSummary =
  `We are glad that you took an interest in us, as well as the services that we provide. We hope you found the information valuable and insightful.
   If you have any questions or need further assistance, please don't hesitate to reach out. Our team of engineers is always ready to assist you with
    any of your IT and cybersecurity needs. If you wish to contact us, our email is support@thesolutioners.ca or you can call us at +1 (647) 524-3894.
     Remember that your security is our top priority. Thank you for visiting us we hope to see you again soon.`

export const Contact = () => {
  return (
    <VideoPage
    title={"contact"}
      content={
        <ContactContent />
    }
    headerHeight={headerHeight}
    slogan={contactSlogan}
      logo={contactLogo}
      icon={icon}
    mainHeight={mainHeight}
    videoSource={null}
    gradient={contactGradient}
  />
  );
};
