import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/Incident_Logo.png";
import aboutVideo from "../../../assets/videos/Incidence Response.mp4";
import { sectionData } from "./Content/incidentResponse";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { incidentGradient } from "../../../components/sections/content/styles";
import icon from "../../../assets/images/Incident_Response_Icon_Large.png";


//Next time just have a function which generate pages automatically and have react router send me to them, this way I wont have to waste time creating a new component page
const headerHeight = window.innerHeight;
const mainHeight = 8000;
const title = "Incident Response Service";

export const IncidentResponse = () => {
  return (
    <VideoPage
      title={"incident response"}
      content={<ServiceSection sectionData={sectionData} />}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={incidentGradient}
    />
  );
};
