import iconImage from "../../../../assets/images/Risk_Assessment_Icon_Large.png";
import { riskGradient } from "../../../../components/sections/content/styles";
import iconImage2 from "../../../../assets/images/eye3d.png";
import blackArrows from "../../../../assets/images/blackArrows.png";
import blackTest from "../../../../assets/images/blackTest.png";
import blackGear from "../../../../assets/images/blackGear.png";
import blackWarning from "../../../../assets/images/blackWarning.png";
import blackLaptop from "../../../../assets/images/blackLaptop.png";
import bcdr from "../../../../assets/images/BCDR_Icon_Large.png";
import blackUser from "../../../../assets/images/blackUser.png";
import blackShield from "../../../../assets/images/blackShield.png";
import blackBug from "../../../../assets/images/Threat_Detection_Icon.png";
import blackLock from "../../../../assets/images/blackLock.png";
import blackGlobe from "../../../../assets/images/blackGlobe.png";
import blackData from "../../../../assets/images/blackData.png";
import blackAlert from "../../../../assets/images/blackAlert.png";
import blackLightning from "../../../../assets/images/blackLightning.png";
import blackBuilding from "../../../../assets/images/Institution_Icon.png";
import blackBook from "../../../../assets/images/blackBook.png";
import blackMeter from "../../../../assets/images/blackMeter.png";
import blackGraph from "../../../../assets/images/blackGraph.png";
import blackRejection from "../../../../assets/images/Rejection_Icon.png";
import blackClock from "../../../../assets/images/Clock_Icon.png";
import otSecurity from "../../../../assets/images/Operational_Technology_Icon_Large.png";
import riskIcon from "../../../../assets/images/Risk_Assessment_Icon_Large.png";
import systemIcon from "../../../../assets/images/System_Integrators_Icon_Large.png";
import incidentIcon from "../../../../assets/images/Incident_Response_Icon_Large.png";
import toolsIcon from "../../../../assets/images/Tools_Icon.png";

export const sectionData = {
  topic: "risk assessment",

  definition: `Risk Assessment is a systematic process used to identify, evaluate, and prioritize potential risks that
could impact an organization’s operations, assets, or objectives. The goal of risk assessment is to
understand the nature and magnitude of risks, allowing organizations to implement appropriate
measures to mitigate or manage those risks effectively.`,

  importance: `
      Risk Assessment is a critical component of risk management that helps organizations identify, evaluate,
and prioritize risks. By understanding potential risks and their impacts, organizations can develop and
implement effective strategies to mitigate or manage those risks, ensuring business continuity,
regulatory compliance, and overall resilience.`,

  keyPoints: [
    {
      title: `Risk Identification`,
      text: `Purpose: Identify potential risks that could affect the organization. 
        Activities: Identify threats (e.g., natural disasters, cyber-attacks, equipment failures), vulnerabilities (e.g., weak security measures, outdated technology), and potential impact areas (e.g., financial loss, operational disruption).
        Tools: Use methods such as brainstorming sessions, historical data analysis, and expert consultations.`,
      icon: blackBug,
    },
    {
      title: `Risk Analysis`,
      text: `Purpose: Evaluate the likelihood and impact of identified risks. 
        Activities: Analyze how likely each risk is to occur and the potential impact on the organization if it does, assessing severity on business operations, financials, reputation, and compliance.
        Techniques: Use qualitative methods (e.g., risk matrices) and quantitative methods (e.g., statistical models) to assess risk levels.`,
      icon: blackGraph,
    },
    {
      title: `Risk Evaluation`,
      text: `Purpose: Prioritize risks based on their assessed likelihood and impact. 
        Activities: Compare and rank risks to determine which require immediate attention and which can be managed with less urgency, aiding in resource allocation and focus on critical risks.
        Criteria: Use criteria such as risk tolerance levels, potential damage, and frequency of occurrence.`,
      icon: blackTest,
    },
    {
      title: `Risk Mitigation and Control`,
      text: `Purpose: Develop strategies to manage, mitigate, or eliminate risks. 
        Activities: Implement control measures to reduce the likelihood or impact of risks, including preventive measures (e.g., security upgrades), detective measures (e.g., monitoring systems), and corrective measures (e.g., contingency plans).
        Strategies: Employ risk avoidance (changing plans to avoid risks), risk reduction (implementing measures to reduce risk impact), risk sharing (outsourcing or insurance), and risk acceptance (accepting the risk without additional measures)`,
      icon: blackMeter,
    },
    {
      title: `Risk Monitoring and Review`,
      text: `Purpose: Continuously monitor and review risk factors and management strategies. 
        Activities: Regularly review and update the risk assessment process to account for changes in the organization, external environment, and emerging risks. Conduct periodic audits and assessments to ensure effectiveness.
        Tools: Use risk management software, performance indicators, and feedback mechanisms.`,
      icon: blackLaptop,
    },
  ],

  stats: [
    {
      title: `Effectiveness of Risk Assessments`,
      text: `A 2023 survey by the Ponemon Institute found that 72% of
organizations have implemented formal risk assessment processes to identify and manage
cybersecurity risks effectively.`,
      icon: blackGraph
    },
    {
      title: `Impact of Cyber Threats`,
      text: `Cost of Cyber-crime: The 2024 Cybersecurity Ventures report estimates that cybercrime will cost
the world $10.5 trillion annually by 2025, emphasizing the significant financial impact of cyber
threats and the necessity for robust risk management.`,
      icon: blackWarning
    },
    {
      title: `Risk Management Practices`,
      text: `The 2024 Gartner Cybersecurity Risk Management Survey
revealed that 69% of organizations have integrated risk management practices into their overall
cybersecurity strategy, highlighting a growing recognition of its importance.`,
      icon: blackBook
    },
  ],

  subtopics: null, //Save this for later

  pitchTitle: `Protect Your Business with Expert Risk Assessment Services`,

  pitch: `Experience the Difference with The Solutioners. Our expert Risk Assessment services are
designed to provide you with a clear understanding of your risk landscape and equip you with the tools
to manage and mitigate those risks effectively. Protect your business, enhance your resilience, and make
informed decisions with confidence. Contact us today to learn how our Risk Assessment services can
secure your organization’s future.`,

  //Also have another card section for why you should choose our risk assessment services

  conclusion: `In the ever-evolving landscape of cyber threats, ensuring your business is protected from potential
attacks is more crucial than ever. At The Solutioners, we specialize in providing cutting-edge
Cyber Risk Assessment services designed to identify, evaluate, and mitigate cyber threats before they
compromise your operations. Our expert team delivers targeted insights and robust solutions to fortify
your digital defenses and keep your organization secure.`,

  videoCaptions: `In a world of evolving cyber threats, understanding your vulnerabilities is the first step to protecting your business. At The Solutioners, our Risk Assessment services provide a comprehensive evaluation of your systems, networks, and processes. We identify potential risks, prioritize them by impact, and offer actionable recommendations to mitigate them. With our insights, you'll make informed decisions and strengthen your overall security. Don't leave your business exposed—gain the clarity and confidence that comes with knowing your risks.`,
  backgroundInfo: {
    coloringInfo: {
      type: "gradient",
      data: {
        type: "linear",
        angle: 70,
        colorInfo: [
          { color: "rgba(0, 0, 255, 1)", stop: "0" },
          { color: "rgba(105, 0, 255, 1)", stop: "25" },
          { color: "rgba(225, 0, 255, 1)", stop: "50" },
          { color: "rgba(255, 0, 105, 1)", stop: "75" },
          { color: "rgba(255, 0, 55, 1)", stop: "100" },
        ],
      },
    },
    patternInfo: null,
    imageInfo: null,
    floorInfo: null,
  },
  images: [iconImage, iconImage2],
  backgroundInfo2: riskGradient,
  heights: {
    desktop: {
      question: "250px",
      definition: "500px",
      question2: "250px",
      importance: "500px",
      question3: "200px",
      grid: "1500px",
      tabMenu: "1200px",
      list: "900px",
      conclusion: "700px",
      pitch: "750px",
    },
    mobile: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "750px",
      question3: "150px",
      grid: "1700px",
      tabMenu: "750px",
      list: "800px",
      conclusion: "600px",
      pitch: "600px",
    },
  },
};
