import checkerPattern from "../../assets/images/footer_checker_pattern.png";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import logo from "../../assets/images//Main_Logo_Large.png";
import { Logo } from "./Logo";
import { centerItems } from "../sections/content/styleFunctions";
import { NavLink } from "react-router-dom";
import { useRef } from "react";
import phoneIcon from "../../assets/images/Contact_Icon_Large.png";
import addressIcon from "../../assets/images/Address_Icon.png";
import emailIcon from "../../assets/images/Email_Icon.png";
import linkedIn from "../../assets/images/linkedIn.png";

export const Footer = ({ height }) => {
  const device = useMediaQueries();
  const currentPage = useRef("Home");
  const loadPage = (btnName) => {
    if (currentPage.currentPage !== btnName) {
      if (btnName !== "About") {
        currentPage.currentPage = btnName;
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, window.innerHeight);
      }
    } else {
      window.scrollTo(0, 0); //This doesn't work, might have to use useScrollPosition instead, also it loads for the other pages that are not Home, which I don't want
    }
  };

  return (
    <footer
      style={{
        width: "100%",
        height: `${800}px`,
        position: "relative",
        zIndex: "10",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        backgroundImage: 'linear-gradient(130deg, rgba(15, 15, 20), rgba(15, 15, 25)'
      }}
    >
      <div
        style={{
          width: "100%",
          height: "95%",
          backgroundImage: `linear-gradient(130deg, rgba(20, 20, 25), rgba(20, 20, 30)`,
          fontSize: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "rgba(243,245,250, 1)",
          boxShadow: " 0px 0px 10px 1px rgba(0,0,0, 0.4)",
          flexDirection: "column",
          // borderTop: "1px solid rgba(255,255,255, 0.9)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85%",
            width: "100%",
            flexDirection: device === "desktop" ? "row" : "column",
          }}
        >
          <div
            className="left-side"
            style={{
              borderRight: device === "desktop" && "1px solid rgba(60, 60, 80)",
              borderBottom:
                device !== "desktop" && "1px solid rgba(60, 60, 80)",
              height: device === "desktop" ? "80%" : '90%',
              width: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                height: device === "desktop" ? "90%" : '80%',
                width: "60%",
                display: "flex",
                alignItems: device === "desktop" ? "flex-end" : "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <div
                style={{
                  height: device === "desktop" ? "30%" : "35%",
                  width: "100%",
                  ...centerItems,
                }}
              >
                <div
                  style={{
                    maskImage: `url(${logo})`,
                    maskSize: "100%",
                    maskRepeat: "no-repeat",
                    WebkitMaskImage: `url(${logo})`,
                    WebkitMaskSize: "100%",
                    WebkitMaskRepeat: "no-repeat",
                    backgroundColor: "white",
                    aspectRatio: "5 / 1",
                    minWidth: device === "desktop" ? "200px" : "220px",
                    width: device === "desktop" ? "400px" : "220px",
                  }}
                ></div>
              </div>
              <div
                className="container"
                style={{
                  height: device === "desktop" ? "60%" : "45%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "flex-start",
                }}
              >
                <article
                  style={{
                    fontSize: device === "desktop" ? "2.1cqw" : "17px",
                    color: "rgba(200,200,200)",
                    display: "table",
                  }}
                >
                  <p
                    style={{
                      display: "table-cell",
                      verticalAlign: device === "desktop" ? "top" : "middle",
                      textAlign: device === "desktop" ? "right" : "center",
                    }}
                  >
                    Our mission is to provide you with the most advanced and
                    effective security services to protect your business from
                    evolving digital threats
                  </p>
                </article>
              </div>
              <div
                className="link container"
                style={{
                  height: device === "desktop" ? "10%" : "25%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    aspectRatio: "1 / 1",
                    // backgroundImage: `url(${linkedIn})`,
                  }}
                >
                  <NavLink
                    exact
                    to={"https://www.linkedin.com/company/the-solutioners-ca"}
                    target="_blank"
                  >
                    <div
                      style={{
                        height: "30px",
                        aspectRatio: "1 / 1",
                        maskImage: `url(${linkedIn})`,
                        maskSize: "100%",
                        maskRepeat: "no-repeat",
                        WebkitMaskImage: `url(${linkedIn})`,
                        WebkitMaskSize: "100%",
                        WebkitMaskRepeat: "no-repeat",
                        backgroundColor: "white",
                      }}
                    ></div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div
            className="center"
            style={{
              height: device === "desktop" ? "80%" : '60%',
              width: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: device === "desktop" && "1px solid rgba(60, 60, 80)",
              borderBottom:
                device !== "desktop" && "1px solid rgba(60, 60, 80)",
            }}
          >
            <div
              style={{
                height: "90%",
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <div style={{ display: "table" }}>
                <h1
                  style={{
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "center",
                    fontSize: device === "desktop" ? "48px" : "32px",
                  }}
                >
                  Links
                </h1>
              </div>
              <NavLink
                exact
                to={"/"}
                style={{ display: "table" }}
                onClick={() => loadPage("home")}
              >
                <h2
                  style={{
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "center",
                    color: "rgba(180, 180, 200)",
                    fontSize: device === "desktop" ? "30px" : "20px",
                  }}
                >
                  Home
                </h2>
              </NavLink>
              <NavLink
                exact
                to={"/services"}
                style={{ display: "table" }}
                onClick={() => loadPage("services")}
              >
                <h2
                  style={{
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "center",
                    color: "rgba(180, 180, 200)",
                    fontSize: device === "desktop" ? "30px" : "20px",
                  }}
                >
                  Services
                </h2>
              </NavLink>
              <NavLink
                exact
                to={"/contact"}
                style={{ display: "table" }}
                onClick={() => loadPage("contact")}
              >
                <h2
                  style={{
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "center",
                    color: "rgba(180, 180, 200)",
                    fontSize: device === "desktop" ? "30px" : "20px",
                  }}
                >
                  Contact
                </h2>
              </NavLink>
              {/*Make one for terms and policies afterwards */}
            </div>
          </div>
          <div
            className="right-side"
            style={{
              height: device === "desktop" ? "80%" : '60%',
              width: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "90%",
                width: "60%",
                display: "flex",
                alignItems: device === "desktop" ? "flex-start" : "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "10%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      maskImage: `url(${emailIcon})`,
                      maskSize: "100%",
                      maskRepeat: "no-repeat",
                      WebkitMaskImage: `url(${emailIcon})`,
                      WebkitMaskSize: "100%",
                      WebkitMaskRepeat: "no-repeat",
                      backgroundColor: "white",
                      width: "40px",
                      aspectRatio: " 1 / 1",
                    }}
                  ></div>
                </div>
                <div style={{ display: "table", height: "100%", width: "70%" }}>
                  <p
                    style={{
                      display: "table-cell",
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontSize: "20px",
                    }}
                  >
                    support@thesolutioners.ca
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "10%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      maskImage: `url(${phoneIcon})`,
                      maskSize: "100%",
                      maskRepeat: "no-repeat",
                      WebkitMaskImage: `url(${phoneIcon})`,
                      WebkitMaskSize: "100%",
                      WebkitMaskRepeat: "no-repeat",
                      backgroundColor: "white",
                      aspectRatio: " 1 / 1",
                      width: "40px",
                    }}
                  ></div>
                </div>
                <div style={{ display: "table", height: "100%", width: "70%" }}>
                  <p
                    style={{
                      display: "table-cell",
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontSize: "20px",
                    }}
                  >
                    +1-647-524-3894
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "10%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      maskImage: `url(${addressIcon})`,
                      maskSize: "100%",
                      maskRepeat: "no-repeat",
                      WebkitMaskImage: `url(${addressIcon})`,
                      WebkitMaskSize: "100%",
                      WebkitMaskRepeat: "no-repeat",
                      backgroundColor: "white",
                      aspectRatio: " 1 / 1",
                      width: "40px",
                    }}
                  ></div>
                </div>
                <div style={{ display: "table", height: "100%", width: "70%" }}>
                  <p
                    style={{
                      display: "table-cell",
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontSize: "20px",
                    }}
                  >
                    100 Mural St, Richmond Hill, ON L4B 1J3
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "10%",
            width: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            borderTop: "1px solid rgba(60, 60, 80)",
          }}
        >
          <div style={{ display: "table" }}>
            <p
              style={{
                display: "table-cell",
                textAlign: "center",
                verticalAlign: "center",
              }}
            >
              © 2024 The Solutioners. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
