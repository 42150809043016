import React from "react";
import {
  blackFlat,
  contentStyles,
  purpleBlueGradient,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
  transparent,
  white,
} from "./styles";
import { Container } from "./Container";
import { generateStyle } from "./styleFunctions";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

const getFactor = (value) => {
  if (value % 4 === 0) {
    return 4;
  } else {
    return 3;
  }
};

export const ServiceSection = ({ sectionData }) => {
  const device = useMediaQueries();
  const formatQuestion = (type, topic, backgroundInfo, height) => {
    let question = `What is ${topic} ?`;
    let stylizedWord = `${topic}`;

    if (type === 2) {
      question = `What is the importance of ${topic} ?`;
      stylizedWord = "importance";
    } else if (type === 3) {
      question = `What are some key parts of ${topic} ?`;
      stylizedWord = "key parts";
    } else if (type === 4) {
      question = `What are ${topic} ?`;
    } else if (type === 5) {
      question = `Why are ${topic} important ?`;
      stylizedWord = "important";
    } else if (type === 6) {
      question = `Why are ${topic} responsible for ?`;
      stylizedWord = "responsible";
    } else if (type === 7) {
      question = `What is a ${topic} solution ?`;
    } else if (type === 8) {
      question = `Why is a ${topic} important ?`;
      stylizedWord = "important";
    } else if (type === 9) {
      question = `What are some key features of a ${topic} ?`;
      stylizedWord = "key features";
    }

    return {
      // height: "500px",
      height: height,
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "content",
                  style: contentStyles.transparentBackgrounds.transparent,
                  height: "100%",
                  contentData: {
                    type: "title",
                    text: question,
                    textColor: "black",
                    xAlignment: "center",
                    yAlignment: "middle",
                    stylizedText: [stylizedWord],
                    continuous: false,
                    backgroundInfo: backgroundInfo,
                    fontSize: device === "desktop" ? "48px" : "36px",
                  },
                },
              ],
            },
          ],
          divideLine: "",
        },
      ],
    };
  };

  const formatDefinition = (
    definition,
    backgroundInfo,
    image,
    height,
    stylizedText
  ) => {
    if (device === "desktop") {
      return {
        // height: "500px",
        height: height,
        style: superSectionStyles.blackDiagonalBackground,
        contentData: [
          {
            type: "section",
            height: "100%",
            style: sectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "subsection",
                height: "100%",
                style: subsectionStyles.transparentBackgrounds.transparent,
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "100%",
                    contentData: {
                      type: "imageIcon",
                      src: image,
                      width: "70%",
                      backgroundInfo: backgroundInfo,
                      animationInfo: {
                        name: "slideRight",
                        duration: 0.5,
                        timing: "ease-in-out",
                        direction: "normal",
                        delay: 0.1,
                      },
                    },
                  },
                ],
              },
              {
                type: "subsection",
                height: "100%",
                style: subsectionStyles.transparentBackgrounds.transparent,
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "100%",
                    contentData: {
                      type: "subtitle",
                      text: `${definition}`,
                      textColor: "white",
                      xAlignment: "left",
                      yAlignment: "middle",
                      stylizedText: [],
                      continuous: false,
                      backgroundInfo: backgroundInfo,
                      fontSize: "24px",
                    },
                  },
                ],
              },
            ],
            divideLine: "",
          },
        ],
      };
    } else {
      return {
        // height: "1000px",
        height: height,
        style: superSectionStyles.blackDiagonalBackground,
        contentData: [
          {
            type: "section",
            height: "100%",
            style: sectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "subsection",
                height: "100%",
                style: subsectionStyles.transparentBackgrounds.transparent,
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "45%",
                    contentData: {
                      type: "imageIcon",
                      src: image,
                      width: "30%",
                      backgroundInfo: backgroundInfo,
                      animationInfo: {
                        name: "slideRight",
                        duration: 0.5,
                        timing: "ease-in-out",
                        direction: "normal",
                        delay: 0.1,
                      },
                    },
                  },
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "55%",
                    contentData: {
                      type: "subtitle",
                      text: `${definition}`,
                      textColor: "white",
                      xAlignment: "center",
                      yAlignment: "middle",
                      stylizedText: [],
                      continuous: false,
                      backgroundInfo: backgroundInfo,
                      fontSize: "24px",
                    },
                  },
                ],
              },
            ],
            divideLine: "",
          },
        ],
      };
    }
  };

  const formatPitch = (
    pitchTitle,
    pitch,
    backgroundInfo,
    height,
    stylizedText
  ) => {
    return {
      height: height,
      // height: "850px",
      style: superSectionStyles.whiteFlatBackgroundFull,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparentFull,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "content",
                  style: contentStyles.transparentBackgrounds.transparent,
                  height: device === "desktop" ? "30%" : pitchTitle ? "30%" : '20%',
                  contentData: {
                    type: "title",
                    text: pitchTitle ? pitchTitle : "Why choose us ?",
                    textColor: "black",
                    xAlignment: "center",
                    yAlignment:  "middle",
                    stylizedText: [`${pitchTitle === "" && "us"}`],
                    continuous: false,
                    backgroundInfo: backgroundInfo,
                    fontSize: device === "desktop" ? "48px" : "36px",
                  },
                },
                {
                  type: "content",
                  style: contentStyles.flatBackgrounds.blackDiagonals,
                  height: device === "desktop" ? "70%" : pitchTitle ? "70%" : '80%',
                  contentData: {
                    type: "subtitle",
                    text: `${pitch}`,
                    textColor: 'white',
                    xAlignment: "center",
                    yAlignment: "middle",
                    stylizedText: [],
                    continuous: false,
                    backgroundInfo: backgroundInfo,
                    fontSize: device === "desktop" ? "36px" : "24px",
                  },
                },
              ],
            },
          ],
          divideLine: "",
        },
      ],
    };
  };

  const formatList = (data, formatType, backgroundInfo, height, titleType) => {
    let formattedList = {};

    if (formatType === "grid") {
      formattedList = {
        // height: "2400px",
        height: height,
        style: superSectionStyles.blackDiagonalBackground,
        contentData: [
          {
            type: "section",
            height: "100%",
            style: sectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "subsection",
                height: "100%",
                style: subsectionStyles.transparentBackgrounds.transparent,
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "90%",
                    contentData: {
                      type: "grid",
                      numberOfColumns:
                        device === "desktop" ? getFactor(data.length) : 1,
                      contentData: data,
                      style: contentStyles.flatBackgrounds.doubleFlatLight,
                      animationInfo: {
                        name: "drop",
                        duration: 0.5,
                        timing: "ease-in-out",
                        direction: "normal",
                        delay: 0,
                      },
                    },
                  },
                ],
              },
            ],
            divideLine: "",
          },
        ],
      };
    } else if (formatType === "list") {
      formattedList = {
        // height: "1000px",
        height: height,
        style: {
          outer: {
            ...generateStyle(
              "outer",
              "center",
              "flat",
              "hidden",
              "100%",
              "100%",
              true,
              {
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPositionX: "100%",
              }
            ),
            backgroundInfo: backgroundInfo,
          },
          inner: {
            ...generateStyle(
              "inner",
              null,
              "transparent",
              "visible",
              "90%",
              "90%",
              false
            ),
            backgroundInfo: transparent,
          },
        },
        contentData: [
          {
            type: "section",
            height: "100%",
            style: sectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "subsection",
                height: "100%",
                style: subsectionStyles.transparentBackgrounds.transparent,
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "90%",
                    contentData: {
                      type: "grid",
                      numberOfColumns: 1,
                      contentData: data,
                      style: contentStyles.flatBackgrounds.darkCard,
                      animationInfo: {
                        name: "slideLeft",
                        duration: 0.5,
                        timing: "linear",
                        direction: "normal",
                        delay: 0,
                      },
                    },
                  },
                ],
              },
            ],
            divideLine: "",
          },
        ],
      };
    } else if (formatType === "tabMenu") {
      formattedList = {
        // height: "1500px",
        height: height,
        style: {
          outer: {
            ...generateStyle(
              "outer",
              "center",
              "flat",
              "hidden",
              "100%",
              "100%",
              true,
              {
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPositionX: "100%",
              }
            ),
            backgroundInfo: backgroundInfo,
          },
          inner: {
            ...generateStyle(
              "inner",
              null,
              "transparent",
              "visible",
              "100%",
              "100%",
              false
            ),
            backgroundInfo: transparent,
          },
        },
        contentData: [
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: device === "desktop" ? "90%" : "100%",
            contentData: {
              type: "tabMenu",
              height: "100%",
              width: device === "desktop" ? "80%" : "100%",
              contentData: data,
              // animationInfo: {
              //   name: "drop",
              //   duration: 1,
              //   timing: "linear",
              //   direction: "normal",
              //   delay: 0.1,
              // },
            },
          },
        ],
      };
    }
    return formattedList;
  };

  const formatCard = (data, type, backgroundInfo) => {
    return data.map((item, i) => {
      if (type === "listItem") {
        if (device === "desktop") {
          return {
            type: "section",
            content: [
              {
                type: "subsection",
                style: subsectionStyles.transparentBackgrounds.transparentCard,
                height: "80%",
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "90%",
                    contentData: {
                      type: "imageIcon",
                      src: item.icon,
                      width: "40%",
                      backgroundInfo: white,
                      animationInfo: null,
                    },
                  },
                ],
                divideLine: "",
              },
              {
                type: "subsection",
                style: subsectionStyles.transparentBackgrounds.transparentCard,
                height: "80%",
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "45%",
                    contentData: {
                      type: "subtitle",
                      text: item.title,
                      textColor: "white",
                      xAlignment: "left",
                      yAlignment: "middle",
                      stylizedText: [],
                      continuous: false,
                      point: false,
                      fontSize: "2.25cqw",
                    },
                  },
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "55%",
                    contentData: {
                      type: "paragraph",
                      text: item.text,
                      textColor: "white",
                      xAlignment: "left",
                      yAlignment: "top",
                      stylizedText: [],
                      continuous: false,
                      fontSize: "1.33cqw",
                    },
                  },
                ],
              },
            ],
          };
        } else {
          return {
            type: "section",
            content: [
              {
                type: "subsection",
                style: subsectionStyles.transparentBackgrounds.transparentCard,
                height: "90%",
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "90%",
                    contentData: {
                      type: "imageIcon",
                      src: item.icon,
                      width: "70%",
                      backgroundInfo: white,
                      animationInfo: null,
                    },
                  },
                ],
                divideLine: "",
              },
              {
                type: "subsection",
                style: subsectionStyles.transparentBackgrounds.transparentCard,
                height: "90%",
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "30%",
                    contentData: {
                      type: "subtitle",
                      text: item.title,
                      textColor: "white",
                      xAlignment: "left",
                      yAlignment: "middle",
                      stylizedText: [],
                      continuous: false,
                      point: false,
                      fontSize: "24px",
                    },
                  },
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "60%",
                    contentData: {
                      type: "paragraph",
                      text: item.text,
                      textColor: "white",
                      xAlignment: "left",
                      yAlignment: "top",
                      stylizedText: [],
                      continuous: false,
                      fontSize: "14px",
                    },
                  },
                ],
              },
            ],
          };
        }
      } else if (type === "card") {
        if (device === "desktop") {
          return {
            type: "content",
            content: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "50%",
                contentData: {
                  type: "imageIcon",
                  src: item.icon,
                  width: "30%",
                  backgroundInfo: backgroundInfo,
                  animationInfo: null,
                },
              }, //This will be replaced with the icons later on
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "13%",
                contentData: {
                  type: "title",
                  text: item.title,
                  textColor: "black",
                  xAlignment: "center",
                  yAlignment: "middle",
                  stylizedText: [],
                  continuous: false,
                  point: false,
                  fontSize: "30px",
                },
              },
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "55%",
                contentData: {
                  type: "paragraph",
                  text: item.text,
                  textColor: "rgba(50,50,70)",
                  xAlignment: "center",
                  yAlignment: "top",
                  stylizedText: [],
                  continuous: false,
                  fontSize: "1cqw",
                },
              },
            ],
          };
        } else {
          return {
            type: "section",
            content: [
              {
                type: "subsection",
                style: subsectionStyles.transparentBackgrounds.transparentCard,
                height: "90%",
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "32%",
                    contentData: {
                      type: "imageIcon",
                      src: item.icon,
                      width: "60%",
                      backgroundInfo: backgroundInfo,
                      animationInfo: null,
                    },
                  }, //This will be replaced with the icons later on
                ],
              },
              {
                type: "subsection",
                style: subsectionStyles.transparentBackgrounds.transparentCard,
                height: "90%",
                contentData: [
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "30%",
                    contentData: {
                      type: "subtitle",
                      text: item.title,
                      textColor: "black",
                      xAlignment: "left",
                      yAlignment: "middle",
                      stylizedText: [],
                      continuous: false,
                      point: false,
                      fontSize: "24px",
                    },
                  },
                  {
                    type: "content",
                    style: contentStyles.transparentBackgrounds.transparent,
                    height: "65%",
                    contentData: {
                      type: "paragraph",
                      text: item.text,
                      textColor: "rgba(50,50,70)",
                      xAlignment: "left",
                      yAlignment: "middle",
                      stylizedText: [],
                      continuous: false,
                      fontSize: "13px",
                    },
                  },
                ],
              },
            ],
          };
        }
      } else if (type === "page") {
        return {
          title: item.title,
          content: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "30%",
              contentData: {
                type: "title",
                text: '', //This will be the icon
                textColor: "black",
                xAlignment: "center",
                yAlignment: "middle",
                stylizedText: [],
                continuous: false,
                fontSize: device === "desktop" ? "48px" : "36px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "20%",
              contentData: {
                type: "subtitle",
                text: item.subtitle, //This will be the subtitle
                textColor: "black",
                xAlignment: "center",
                yAlignment: "middle",
                stylizedText: [],
                continuous: false,
                fontSize: device === "desktop" ? "2.25cqw" : "24px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "paragraph",
                text: item.text,
                textColor: "black",
                xAlignment: "center",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                fontSize: device === "desktop" ? "1.33cqw" : "16px",
              },
            },
          ],
        };
      }
    });
  };

  const formatSections = (sectionData) => {
    const {
      topic,
      definition,
      importance,
      keyPoints,
      stats,
      subtopics,
      pitchTitle,
      pitch,
      conclusion,
      backgroundInfo,
      backgroundInfo2,
      images,
      heights,
    } = sectionData;
    const cardData = formatCard(keyPoints, "card", backgroundInfo);
    const listData = formatCard(stats, "listItem", backgroundInfo);
    const sectionHeights =
      device === "desktop" ? heights.desktop : heights.mobile;
    let subTopicBreakDown = null;
    if (subtopics !== null) {
      const subtopicData = formatCard(subtopics, "page", backgroundInfo);
      subTopicBreakDown = formatList(
        subtopicData,
        "tabMenu",
        backgroundInfo2,
        sectionHeights.tabMenu
      );
    }

    let questions = [1, 2, 3];
    if (topic === "system integrators") {
      questions = [4, 5, 6];
    } else if (topic === "BDR") {
      questions = [7, 8, 9];
    }

    return [
      formatQuestion(
        questions[0],
        topic,
        backgroundInfo,
        sectionHeights.question
      ),
      formatDefinition(
        definition,
        backgroundInfo,
        images[0],
        sectionHeights.definition
      ),
      formatQuestion(
        questions[1],
        topic,
        backgroundInfo,
        sectionHeights.question2
      ),
      formatDefinition(
        importance,
        null,
        images[1],
        sectionHeights.importance
      ),
      formatQuestion(
        questions[2],
        topic,
        backgroundInfo,
        sectionHeights.question3
      ),
      formatList(cardData, "grid", backgroundInfo, sectionHeights.grid),
      formatList(listData, "list", backgroundInfo2, sectionHeights.list),
      formatPitch("", conclusion, backgroundInfo, sectionHeights.conclusion),
      subTopicBreakDown,
      formatPitch(pitchTitle, pitch, backgroundInfo, sectionHeights.pitch),
    ];
    //return formatted sections as object to give to generateContainers
  };
  const generateContainers = (sectionInfo) => {
    const containers = sectionInfo.map((section, index) => {
      if (section !== null) {
        return (
          <Container
            type={"superSection"}
            height={section.height}
            width={"100%"}
            style={section.style}
            index={index}
            contentData={section.contentData}
          />
        );
      } else {
        return;
      }
    });
    return containers;
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {generateContainers(formatSections(sectionData))}
    </div>
  );
};

//Takes data, and organizes it into format
/*
Format: (This is the general order, I can change the number)
0) Header and then Video

1) Question 1: What is (Service) ?
2) Answer: Definition (For design add gradient border to one side of of the text), then have an icon to the side which shows the service, use the gradient icons I have
3) Question 2: What is the importance of [service] ? 
4) Answer: Reason (On the other side is a 3d icon that is seen floating up and down)
5) Fact and Stat cards (windows, grid or row of cards, accordion, or article/list with cards going down, usage of animated and highlighted words and numbers as well)
6) Points display/Break down, can be done through either these formats window, grid, accordion, article with cards going down, each with their own icons as well) (Optional)
7) Sub-services: Break down of main subservices/features. (Optional)
8) Why choose us? Why choose us (Image or video background)
9) In short: Conclusion

10) Footer

6) and 7) Can be combined or separated
5) Can also include case study cards or question and answer cards as well

*/
