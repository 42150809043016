import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/OT_Logo.png";
import aboutVideo from "../../../assets/videos/Operational Technology Services.mp4";
import { sectionData } from "./Content/otSecurity.js";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { otGradient } from "../../../components/sections/content/styles.js";
import icon from "../../../assets/images/Operational_Technology_Icon_Large.png";

const headerHeight = window.innerHeight;
const mainHeight = 10000;
const title = "OT Security Services";

export const OperationalTechnology = () => {
  return (
    <VideoPage
      title={"ot security"}
      content={<ServiceSection sectionData={sectionData} />}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={otGradient}
    />
  );
};
