import { useEffect, useRef, useState } from "react";

const getTextBoxHeight = (fontSize, textLength, width) => {
  const numberOfLines = Math.round((fontSize / 100) * textLength * 0.45); //This is for cqw
  const boxHeight = Math.round((numberOfLines * width * fontSize) / 100);
  return boxHeight;
};

export const Scroller = ({
  baseHeight,
  baseWidth,
  direction,
  text,
  duration,
  started = true,
  paused,
  fps = 60,
  fontSize = 3,
}) => {
  const boxHeight = useRef(getTextBoxHeight(fontSize, text.length, baseWidth));
  const [position, setPosition] = useState(-baseHeight);
  const startTime = useRef(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      if (position <= boxHeight.current && started) {
        if (!paused) {
          const endTime = Date.now();
          setPosition(
            (prev) =>
              prev +
              ((endTime - startTime.current) *
                Math.round(boxHeight.current + baseHeight)) /
                (duration * 1000)
          );
        }
      } else {
        setPosition(-baseHeight);
      }
    }, 1000 / fps);
    startTime.current = Date.now();
    return () => {
      clearInterval(interval);
    };
  }, [position, started, paused]);

  return (
    <div
      style={{
        height: baseHeight + "px",
        width: baseWidth + "px",
        maskImage:
          "linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.25) 12%, black 30%, black 70%, rgba(0, 0, 0, 0.25) 88%, transparent)",
        overflow: "hidden",
      }}
    >
      <div
        className="caption container"
        style={{
          height: `${boxHeight.current}px`,
          width: "100%",
          color: "white",
          position: "relative",
          top: `${-position}px`,
          display: "table",
        }}
      >
        <h1
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
            fontSize: `${(fontSize / 100) * baseWidth}px`,
          }}
        >
          {text}
        </h1>
      </div>
    </div>
  );
};

//Duration/Speed control
//Ability to go in from the side
//Perfect length control so I don't need to put in a random percentage
//Later on the number slow machine
