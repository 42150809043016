import React from "react";
import { Container } from "../../../components/sections/content/Container";
import {
  contactGradient,
  contentStyles,
  lightDiagonalGlass,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
  white,
} from "../../../components/sections/content/styles";
import { ContactForm } from "./ContactForm";
import { generateStyle } from "../../../components/sections/content/styleFunctions";
import contactBackground from "../../../assets/images/Contact_Background_2.jpg";
import phoneIcon from "../../../assets/images/Contact_Icon_Large.png";
import addressIcon from "../../../assets/images/Address_Icon.png";
import emailIcon from "../../../assets/images/Email_Icon.png";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

export const ContactContent = () => {
  const device = useMediaQueries();
  const formContent = [
    {
      type: "section",
      height: "100%",
      style: sectionStyles.glassBackgrounds.whiteGlass25,
      contentData: [
        {
          type: "content",
          style: contentStyles.transparentBackgrounds.transparent,
          height: "100%",
          contentData: {
            type: "component",
            style: contentStyles.transparentBackgrounds.transparent,
            contentData: <ContactForm />,
          },
        },
      ],
    },
    {
      type: "section",
      height: "100%",
      style: sectionStyles.transparentBackgrounds.transparent,
      contentData: [
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "20%",
              contentData: {
                type: "title",
                textColor: "rgba(255,255,255,0.6)",
                xAlignment: "left",
                yAlignment: "bottom",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Contact Us Today`,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "5%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "left",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                    Have a question or ready to start a conversation about securing your business?`,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "30%%",
              contentData: {
                type: "paragraph",
                textColor: "white",
                xAlignment: "left",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                        Get in touch with us through the contact form on the left, or use the contact details provided above.
                        Our team is eager to hear from you and provide the support you need.`,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "subtitle",
                textColor: "white",
                xAlignment: "left",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                            Get in touch with us through the form, or use the contact details provided above.
                            Our team is eager to hear from you and provide the support you need.`,
              },
            },
          ],
        },
      ],
    },
  ];

  const section1Content = [
    {
      type: "section",
      height: "100%",
      style: sectionStyles.transparentBackgrounds.transparent,
      contentData: [
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "title",
                textColor: "rgba(255,255,255,1)",
                xAlignment: "center",
                yAlignment: "middle",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Why Contact Us ?`,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "center",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                    Our team of cybersecurity professionals is dedicated to providing exceptional service and support.
  Whether you’re looking to learn more about our services, need a customized security solution, or have
  specific questions, we are here to help. Reach out to us today and discover how we can safeguard your
  digital assets and support your business goals.`,
              },
            },
          ],
        },
      ],
    },
  ];

  const section2Content = [
    {
      type: "section",
      height: "100%",
      style: sectionStyles.transparentBackgrounds.transparent,
      contentData: [
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "title",
                textColor: "black",
                xAlignment: "center",
                yAlignment: "middle",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Get in Touch`,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(80,80,80)",
                xAlignment: "center",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                    Whether you have a question about our services, need technical support, or want to discuss how we can
  help secure your business, we’re here to assist you. Our team of experts is ready to provide you with the
  information and support you need to make informed decisions about your cybersecurity strategy.`,
              },
            },
          ],
        },
      ],
    },
  ];

  const section3Content = [
    {
      type: "section",
      height: "100%",
      style: sectionStyles.transparentBackgrounds.transparent,
      contentData: [
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "title",
                textColor: "rgba(255,255,255,1)",
                xAlignment: "center",
                yAlignment: "bottom",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Our Information`,
                fontSize: device === "desktop" ? "64px" : "48px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "50%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "center",
                yAlignment: "middle",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                  Visit us or reach out through the following contact details`,
                fontSize: device === "desktop" ? "36px" : "24px",
              },
            },
          ],
        },
      ],
    },
  ];

  const section4Content = [
    {
      type: "section",
      height: "100%",
      style: sectionStyles.transparentBackgrounds.transparent,
      contentData: [
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparentRight,
              height: "33%",
              contentData: {
                type: "imageIcon",
                src: addressIcon,
                width: device === "desktop" ? "20%" : "45%",
                backgroundInfo: white,
                animationInfo: null,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparentRight,
              height: "33%",
              contentData: {
                type: "imageIcon",
                src: phoneIcon,
                width: device === "desktop" ? "20%" : "45%",
                backgroundInfo: white,
                animationInfo: null,
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparentRight,
              height: "33%",
              contentData: {
                type: "imageIcon",
                src: emailIcon,
                width: device === "desktop" ? "20%" : "45%",
                backgroundInfo: white,
                animationInfo: null,
              },
            },
          ],
        },
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "10%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(255,255,255,1)",
                xAlignment: "left",
                yAlignment: "bottom",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Address`,
                fontSize: device === "desktop" ? "36px" : "24px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "10%",
              contentData: {
                type: "paragraph",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "left",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
  100 Mural St, Richmond Hill, ON L4B 1J3`,
                fontSize: device === "desktop" ? "24px" : "16px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "10%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(255,255,255,1)",
                xAlignment: "left",
                yAlignment: "bottom",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Phone`,
                fontSize: device === "desktop" ? "36px" : "24px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "10%",
              contentData: {
                type: "paragraph",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "left",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                    +1-647-524-3894`,
                fontSize: device === "desktop" ? "24px" : "16px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "10%",
              contentData: {
                type: "subtitle",
                textColor: "rgba(255,255,255,1)",
                xAlignment: "left",
                yAlignment: "bottom",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `Email`,
                fontSize: device === "desktop" ? "36px" : "24px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "10%",
              contentData: {
                type: "paragraph",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "left",
                yAlignment: "top",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                    support@thesolutioners.ca`,
                fontSize: device === "desktop" ? "24px" : "16px",
              },
            },
          ],
        },
      ],
    },
  ];

  const section5Content = [
    {
      type: "section",
      height: "100%",
      style: sectionStyles.transparentBackgrounds.transparent,
      contentData: [
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "100%",
              contentData: {
                type: "paragraph",
                textColor: "rgba(255,255,255,0.8)",
                xAlignment: "center",
                yAlignment: "middle",
                stylizedText: [],
                continuous: false,
                backgroundInfo: {},
                text: `
                    Feel free to stop by, call, or email us during our business hours. We look forward to connecting with
  you!`,
                fontSize: device === "desktop" ? "32px" : "20px",
              },
            },
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "100%",
              contentData: {
                name: "hover",
                type: "buttonLink",
                text: "Email Us",
                buttonHeight: "50px",
                buttonWidth: "250px",
                backgroundInfo: contactGradient,
                style: "floating",
                link: "mailto:support@thesolutioners.ca",
                fontSize: "20px",
                // textColor: "black",
              },
            },
          ],
        },
      ],
    },
  ];
  return (
    <>
      {/* <Container
        type={"superSection"}
        height={600}
        width={"100%"}
        style={superSectionStyles.blackDiagonalBackground}
        index={0}
        contentData={section1Content}
      /> */}
      {/* <Container
        type={"superSection"}
        height={600}
        width={"100%"}
        style={superSectionStyles.whiteFlatBackground}
        index={0}
        contentData={section2Content}
      /> */}
      <Container
        type={"superSection"}
        height={300}
        width={"100%"}
        style={superSectionStyles.blackDiagonalBackground}
        index={0}
        contentData={section3Content}
      />
      <Container
        type={"superSection"}
        height={600}
        width={"100%"}
        style={superSectionStyles.blackDiagonalBackground}
        index={0}
        contentData={section4Content}
      />
      <Container
        type={"superSection"}
        height={400}
        width={"100%"}
        style={superSectionStyles.blackDiagonalBackground}
        index={0}
        contentData={section5Content}
      />
      {/* <Container
        type={"superSection"}
        height={1050}
        width={"100%"}
        style={{
          outer: {
            ...generateStyle(
              //Might need to change this
              "outer",
              "center",
              "flat",
              "hidden",
              "100%",
              "100%",
              false
            ),
            backgroundInfo: lightDiagonalGlass,
          },
          inner: {
            ...generateStyle(
              "inner",
              null,
              "flat-shadow",
              "visible",
              "95%",
              "100%",
              true,
              {
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            ),
            backgroundInfo: {
              coloringInfo: {
                type: "gradient",
                data: {
                  type: "linear",
                  angle: 80,
                  colorInfo: [
                    { color: "rgba(255, 0, 80, 0.3)", stop: "0" },
                    { color: "rgba(255, 0, 170, 0.5)", stop: "30" },
                    { color: "rgba(255,20,255, 0.9)", stop: "60" },
                    { color: "rgba(160,120,240, 1)", stop: "100" },
                  ],
                },
              },
              patternInfo: null,
              imageInfo: {
                image: contactBackground,
              },
              floorInfo: null,
            },
          },
        }}
        index={0}
        contentData={formContent}
      /> */}
    </>
  );
};

{
  /* <p><a href="mailto:someone@example.com">Send email</a></p> */
}
