import { useEffect, useRef, useState } from "react";
import { useScrollPosition } from "../../../hooks/useScrollPosition";
import { VideoCaption } from "./VideoCaption";
import { centerItems } from "../content/styleFunctions";

export const FadingVideoSection = ({
  source,
  repeat,
  fadeInTime,
  fadeOutTime,
  vidPosition,
  visibility,
  fadeLimit,
  caption,
  autoplay = false,
}) => {
  const vidRef = useRef();
  const pos = useScrollPosition(1);
  const [fadeTimer, setFadeTimer] = useState(0);
  const playerTimer = useRef(0);
  const started = useRef(false);
  const [playing, setPlaying] = useState(false);
  const loaded = useRef(false);
  const finished = useRef(false);
  const opacity = useRef(1);

  const unloadVideo = (timeInSeconds) => {
    if (fadeTimer <= timeInSeconds * 1000) {
      //For the end fade I will need to get the duration of the video and start the fade right before the video ends
      opacity.current = fadeTimer / (1000 * timeInSeconds);
    } else {
      finished.current = true;
      setFadeTimer(0);
    }
  };

  const loadVideo = (timeInSeconds, video) => {
    const vid = vidRef.current;
    if (!vid) return;

    if (fadeTimer <= timeInSeconds * 1000) {
      opacity.current = 1 - fadeTimer / (1000 * timeInSeconds);
      if (fadeTimer >= timeInSeconds * 500 && !playing) {
        if (
          video.currentTime >= 0 &&
          video.paused &&
          !video.ended &&
          video.readyState > video.HAVE_CURRENT_DATA
        ) {
          if (autoplay) {
            setPlaying(() => true);
          }
          // vid.load()
        }
      }
    } else {
      loaded.current = true;
      setFadeTimer(0);
    }
  };

  const handleVideo = () => {
    const video = vidRef.current;
    const duration = video.duration;
    const currentTime = video.currentTime;
    if (!video) return;

    if (document.visibilityState === "hidden") {
      endVideo();
      //ends video if another window or tab is selected
    } else {
      if (!finished.current) {
        if (!started.current) {
          if (
            pos >= vidPosition - window.innerHeight / 3 &&
            pos <= vidPosition + window.innerHeight / 3
          ) {
            started.current = true;
            window.scrollTo({ top: vidPosition, behavior: "smooth" });
            // document.body.style.overflow = "hidden"; //disables scrolling
          }
        } else {
          playerTimer.current++;
          if (!loaded.current) {
            // if (pos === vidPosition) {
              setFadeTimer((prev) => prev + 1);
              loadVideo(fadeInTime, video);
            // }
          } else {
            document.body.style.overflow = "auto";
            if (currentTime >= duration - fadeOutTime / 2) {
              setFadeTimer((prev) => prev + 1);
              unloadVideo(fadeOutTime);
            }
          }
        }
        handlePlaying(video);
      } else {
        if (repeat) {
          reset();
        }
      }
    }
  };

  const handlePlaying = (video) => {
    if (!playing) {
      video.pause();
    } else {
      video.play();
    }
  };

  const reset = () => {
    started.current = false;
    finished.current = false;
    loaded.current = false;
    vidRef.current.currentTime = 0;
    playerTimer.current = 0;
    opacity.current = 1;
  };

  const endVideo = () => {
    setPlaying(() => false);
    reset();
  };

  const checkDistance = () => {
    const video = vidRef.current;
    if (!video) return;
    let distance = Math.abs(vidPosition - pos) / window.innerHeight;
    if (distance >= fadeLimit) {
      distance = fadeLimit;
      endVideo();
    } else {
      video.volume = 1 - distance * (1 / fadeLimit); //Lowers volume as video is scrolled away
    }
  };

  const handlePlay = () => {
    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    checkDistance();
    window.addEventListener("visibilitychange", handleVideo);
    let timer = setInterval(handleVideo, 1);

    return () => {
      window.removeEventListener("visibilitychange", handleVideo);
      clearInterval(timer);
    };
  });

  return (
    <section
      className="video-player container"
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
      }}
    >
      <div
        className="play-button container"
        style={{
          height: "100%",
          width: "100%",
          fontSize: "100px",
          position: "absolute",
          ...centerItems,
        }}
      >
        <button
          style={{
            height: "100%",
            width: "100%",
            color: "white",
            zIndex: 100,
            cursor: !finished.current ? "pointer" : "default",
            ...centerItems,
          }}
          onClick={() => handlePlay()}
        >
          {!playing && !finished.current ? " \u25B6" : ""}
        </button>
      </div>
      <div
        className="video container"
        style={{
          height: "100%",
          width: "100%",
          fontSize: "100px",
          color: "white",
        }}
      >
        <VideoCaption
          caption={caption}
          duration={vidRef?.current?.duration}
          visibility={visibility}
          started={started.current && !finished.current}
          paused={!playing}
        />
        <div
          className="screen"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,1)",
            position: "absolute",
            opacity: started.current ? opacity.current : "100%",
          }}
        ></div>
        <div
          className="vignette"
          style={{
            height: "100%",
            width: "100%",
            backgroundImage:
              "radial-gradient(rgba(0,0,0,0), rgba(0,0,0,0.25) 40%, rgba(0,0,0,1))",
            position: "absolute",
          }}
        ></div>
        <video
          src={source}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          // muted="muted"
          ref={vidRef}
          onEnded={() => endVideo()}
        />

        {/* <div className="fadeTimer">{fadeTimer}</div> */}
      </div>
    </section>
  );
};

/*
// Order for now
// 1)Video container scrolls in (Done)
// 2)Then the scroll is disabled, for now, (Done)
// 3)Next the screen fades in (Done)
// 4)Then the video plays just before it reaches quarter opacity 
// 5)Afterwards the scroll is enabled when video finishes
// 6)Finally the video fades out and you can scroll again


Next Steps:
// -Video fadeout
-Fix bug where it occasionally resumes playing after it pauses
// -Turning it all into one component, called fading video
// -Have it be able to play again/repeat, both after scrolling away and when looking at the video
// -Video text overlay for the overlayed videos
// -Ability to Scroll away after the video has started, this will also make the video fade as well through the opacity, 
// so video exiting (more so for the overlayed video)
// -Make a more responsive video container which changes width and height according to the browser window/viewport, but this will need me to convert vh into pixels as well

*/
