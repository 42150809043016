import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/Cyber_Logo.png";
import aboutVideo from "../../../assets/videos/Cybersecurity Services.mp4";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { sectionData } from "./Content/cybersecurity";
import { cyberGradient } from "../../../components/sections/content/styles";
import icon from "../../../assets/images/Cybersecurity_Icon_Large.png";

const headerHeight = window.innerHeight;
const mainHeight = 7000;
const title = "Cybersecurity Services";


export const Cybersecurity = () => {
  return (
    <VideoPage
      title={"cybersecurity"}
      content={<ServiceSection sectionData={sectionData}/>}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={cyberGradient}
    />
  );
};
