import iconImage from "../../../../assets/images/Incident_Response_Icon_Large.png";
import iconImage2 from "../../../../assets/images/lightning3d.png";
import { incidentGradient } from "../../../../components/sections/content/styles";
import blackArrows from "../../../../assets/images/blackArrows.png";
import blackTest from "../../../../assets/images/blackTest.png";
import blackGear from "../../../../assets/images/blackGear.png";
import blackWarning from "../../../../assets/images/blackWarning.png";
import blackLaptop from "../../../../assets/images/blackLaptop.png";
import bcdr from "../../../../assets/images/BCDR_Icon_Large.png";
import blackUser from "../../../../assets/images/blackUser.png";
import blackShield from "../../../../assets/images/blackShield.png";
import blackBug from "../../../../assets/images/Threat_Detection_Icon.png";
import blackLock from "../../../../assets/images/blackLock.png";
import blackGlobe from "../../../../assets/images/blackGlobe.png";
import blackData from "../../../../assets/images/blackData.png";
import blackAlert from "../../../../assets/images/blackAlert.png";
import blackLightning from "../../../../assets/images/blackLightning.png";
import blackBuilding from "../../../../assets/images/Institution_Icon.png";
import blackBook from "../../../../assets/images/blackBook.png";
import blackRejection from "../../../../assets/images/Rejection_Icon.png";
import blackClock from "../../../../assets/images/Clock_Icon.png";
import blackMeter from "../../../../assets/images/blackMeter.png";
import blackDataLoss from "../../../../assets/images/Data_Loss_Icon.png";
import blackMoney from "../../../../assets/images/Money_Icon.png";
import blackCoinCost from "../../../../assets/images/Rising_Expenses_Icon.png";

export const sectionData = {
  topic: "incident response",

  definition: `Incident response refers to the organized approach and set of procedures an organization follows to
address and manage the aftermath of a cybersecurity incident or data breach. The goal of incident
response is to handle the situation in a way that limits damage, reduces recovery time and costs, and
mitigates the impact on the organization.`,

  importance: `
      Incident response is a crucial component of a comprehensive cybersecurity strategy, focused on
effectively managing and mitigating the impact of cyber incidents. By following a structured approach
that includes preparation, identification, containment, eradication, recovery, and lessons learned,
organizations can minimize damage, ensure rapid recovery, and enhance their overall security posture.
Effective incident response not only helps in addressing immediate threats but also strengthens an
organization’s ability to handle future incidents and maintain operational resilience.`,

  keyPoints: [
    {
      title: `Preparation`,
      text: `Purpose: Establish and maintain the necessary capabilities and plans to respond
effectively to incidents.

Activities: Develop an incident response plan (IRP), assemble an incident response team
(IRT), provide training and awareness programs for staff, and ensure the availability of
necessary tools and resources.

Tools: Incident response playbooks, communication plans, and security monitoring
tools.`,
      icon: blackTest,
    },
    {
      title: `Identification`,
      text: `Purpose: Detect and confirm that a security incident or breach has occurred.

        Activities: Monitor security alerts and anomalies, use intrusion detection systems (IDS)
and security information and event management (SIEM) tools to identify potential
incidents, and perform initial assessments to verify the nature and scope of the incident.

Techniques: Analyze logs, conduct network and system scans, and review alert data.`,
      icon: blackBug,
    },
    {
      title: `Containment`,
      text: `Purpose: Limit the spread of the incident and prevent further damage.

Activities: Implement short-term and long-term containment measures to isolate
affected systems, block malicious traffic, and restrict unauthorized access.

Techniques: Disconnect compromised systems from the network, apply firewall rules,
and restrict user accounts or privileges.`,
      icon: blackLock,
    },
    {
      title: `Eradication`,
      text: `Purpose: Remove the root cause and any related artifacts of the incident from the
environment.

Activities: Identify and eliminate the source of the incident, such as malware,
vulnerabilities, or unauthorized access. Ensure that all traces of the threat are
completely removed from affected systems.

Techniques: Use malware removal tools, patch vulnerabilities, and review and enhance
security controls.`,
      icon: blackRejection,
    },
    {
      title: `Recovery`,
      text: `Purpose: Restore and validate system functionality to return to normal operations.

        Activities: Restore systems from clean backups, verify the integrity of systems and data,
and monitor for any signs of recurrence of the incident. Ensure that affected systems
are fully operational and secure before bringing them back online.

Techniques: Perform system and data restoration, conduct post-recovery testing, and
review recovery processes.`,
      icon: blackData,
    },
    {
      title: `Lessons Learned`,
      text: `Purpose: Review and analyze the incident to improve future response and prevention
efforts.

Activities: Conduct a post-incident analysis, document the incident and response
actions, identify areas for improvement, and update the incident response plan and
security measures based on the findings.

Techniques: Create incident reports, debrief with the incident response team, and
conduct a retrospective analysis of the incident handling.`,
      icon: blackBook,
    },
  ],

  stats: [
    {
      title: `Incident Detection and Response Time`,
      text: `According to the 2024 IBM Security X-Force Threat Intelligence
Index, organizations that have an established incident response plan (IRP) and team detect and
contain incidents 65% faster compared to those without a formal plan.`,
      icon: blackClock
    },
    {
      title: `Frequency of Incidents`,
      text: `The 2024 Ponemon Institute Cyber Resilient Organization Report states
that 76% of organizations experienced a significant cybersecurity incident in the past 12 months,
underscoring the need for robust incident response capabilities.`,
      icon: blackMeter
    },
    {
      title: `Cost of Cyber Incidents`,
      text: `The 2024 Cost of a Data Breach Report by IBM and Ponemon Institute found
that organizations with an incident response team in place experienced an average cost of $4.45
million per data breach, compared to $5.49 million for those without a dedicated team,
highlighting the financial benefits of effective incident response.`,
      icon: blackCoinCost
    },
  ],

  subtopics: null,

  pitchTitle: `Protect Your Business with Confidence`,

  pitch: `At The Solutioners, our Incident Response services are designed to safeguard your organization
from the unexpected. By partnering with us, you gain a trusted ally in cybersecurity, prepared to handle
any incident with expertise and precision. Don’t wait for a cyber attack to compromise your
operations—be proactive and ensure you’re ready for any threat. Contact us today to learn how our
Incident Response services can enhance your security strategy and keep your business resilient.`,

  conclusion: `A robust incident response strategy is essential for navigating the complexities of today’s
cyber threat landscape. By investing in preparation, leveraging automation, ensuring compliance, and
committing to continuous improvement, organizations can effectively manage cybersecurity incidents,
minimize their impact, and enhance overall resilience. Effective incident response not only protects
against immediate threats but also fortifies an organization’s long-term security framework, ensuring
sustained operational integrity and confidence in its cybersecurity practices.`,

  videoCaptions: `In cybersecurity, it's not a matter of 'if' an attack will happen—but 'when.' When a breach occurs, every second counts. Our Incident Response team at The Solutioners provides rapid, coordinated, and decisive action to minimize damage and restore normal operations. From containment to recovery, our experts will guide you through every phase of response, investigating the incident's cause and helping prevent future threats. Be prepared for the unexpected—respond with speed and confidence with our Incident Response services.`,
  backgroundInfo: {
    coloringInfo: {
      type: "gradient",
      data: {
        type: "linear",
        angle: 60,
        colorInfo: [
          { color: "rgba(255, 225, 55, 1)", stop: "0" },
          { color: "rgba(255, 150, 0, 1)", stop: "30" },
          { color: "rgba(255, 25, 0, 1)", stop: "75" },
          { color: "rgba(255, 0, 155, 1)", stop: "100" },
        ],
      },
    },
    patternInfo: null,
    imageInfo: null,
    floorInfo: null,
  },
  images: [iconImage, iconImage2],
  backgroundInfo2: incidentGradient,
  heights: {
    desktop: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "600px",
      question3: "200px",
      grid: "1400px",
      tabMenu: "1200px",
      list: "900px",
      conclusion: "600px",
      pitch: "750px",
    },
    mobile: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "900px",
      question3: "150px",
      grid: "1900px",
      tabMenu: "750px",
      list: "1000px",
      conclusion: "700px",
      pitch: "650px",
    },
  },
};
