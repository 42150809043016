import { useRef, useState } from "react";
import { Container } from "../../sections/content/Container";
import {
  contentStyles,
  purpleBlueGradient,
  sectionStyles,
  subsectionStyles,
  transparent,
} from "../../sections/content/styles";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

const generatePage = (contentData, value) => {
  const page = contentData
    .map((data) => {
      return data.content;
    })
    .filter((item, i) => i === value);
  return page[0];
};

const generateTestContent = (numberOfPages) => {
  let content = [];
  for (let i = 0; i < numberOfPages; i++) {
    const page = {
      title: i,
      content: [
        {
          type: "title",
          text: `${i}`,
          textColor: "black",
          xAlignment: "center",
          yAlignment: "middle",
          stylizedText: [],
          continuous: false,
        },
        {
          type: "subtitle",
          text: "subtitle",
          textColor: "black",
          xAlignment: "center",
          yAlignment: "middle",
          stylizedText: [],
          continuous: false,
        },
        {
          type: "paragraph",
          text: "paragraph",
          textColor: "black",
          xAlignment: "center",
          yAlignment: "middle",
          stylizedText: [],
          continuous: false,
        },
      ],
    };
    content = [...content, page];
  }
  return content;
};

export const TabMenu = ({
  height,
  width,
  windowStyle,
  mainStyle,
  contentData,
  animationInfo,
}) => {
  const [visiblePage, setVisiblePage] = useState(0);
  const device = useMediaQueries()

  const setVisible = (index) => {
    setVisiblePage(index);
  };

  const generateButtons = (contentData) => {
    return contentData.map((content, index) => {
      return {
        type: "content",
        height: "10%",
        style: contentStyles.transparentBackgrounds.transparentRight,
        contentData: {
          type: "button",
          name: `${content.title} window-selection-button ${index === visiblePage && "selected"} `,
          text: content.title,
          buttonHeight: "90%",
          buttonWidth: "90%",
          onClick: () => setVisible(index),
          style: "flat",
          horizontalAlignment: "center",
          backgroundInfo: transparent,
          fontSize: device === 'desktop' ? '1.5cqw' : '16px'
        },
      };
    });
  };

  return (
    <Container
      type={"section"}
      height={height}
      width={width}
      style={sectionStyles.windowBackgrounds.darkGlass}
      contentData={[
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.window.windowAside,
          contentData: generateButtons(contentData),
        },
        {
          type: "subsection",
          height: "100%",
          style: subsectionStyles.window.windowMain,
          contentData: [
            {
              type: "content",
              style: contentStyles.transparentBackgrounds.transparent,
              height: "100%",
              contentData: {
                type: "card",
                style: contentStyles.transparentBackgrounds.transparent,
                index: visiblePage,
                content: generatePage(contentData, visiblePage),
              },
            },
          ],
        },
      ]}
      index={0}
      divideLine={""}
    />
  );
};

// checkVisible(generatePages(contentData), visiblePage)
