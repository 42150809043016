import iconImage from "../../../../assets/images/Cloud_Security_Icon_Large.png";
import iconImage2 from "../../../../assets/images/cloud3d.png";
import { cloudGradient } from "../../../../components/sections/content/styles";
import blackArrows from "../../../../assets/images/blackArrows.png";
import blackTest from "../../../../assets/images/blackTest.png";
import blackGear from "../../../../assets/images/blackGear.png";
import blackWarning from "../../../../assets/images/blackWarning.png";
import blackLaptop from "../../../../assets/images/blackLaptop.png";
import bcdr from "../../../../assets/images/BCDR_Icon_Large.png";
import blackUser from "../../../../assets/images/blackUser.png";
import blackShield from "../../../../assets/images/blackShield.png";
import blackBug from "../../../../assets/images/Threat_Detection_Icon.png";
import blackLock from "../../../../assets/images/blackLock.png";
import blackGlobe from "../../../../assets/images/blackGlobe.png";
import blackData from "../../../../assets/images/blackData.png";
import blackAlert from "../../../../assets/images/blackAlert.png";
import blackLightning from "../../../../assets/images/blackLightning.png";
import blackBuilding from "../../../../assets/images/Institution_Icon.png";
import blackBook from "../../../../assets/images/blackBook.png";
import blackDataLoss from "../../../../assets/images/Data_Loss_Icon.png";
import blackMoney from "../../../../assets/images/Money_Icon.png";
import blackCoinCost from "../../../../assets/images/Rising_Expenses_Icon.png";

export const sectionData = {
  topic: "cloud security",

  definition: `Cloud security refers to the set of practices, technologies, and policies designed to protect data,
applications, and services that are hosted in cloud environments. As more organizations move their
operations to the cloud, ensuring robust security in these environments becomes crucial to safeguard
against a range of potential threats.`,

  importance: `
    Cloud security is vital because it ensures the protection of data, applications, and services
     hosted in cloud environments from unauthorized access, breaches, and other cyber threats.
      It safeguards sensitive information by implementing measures
        such as encryption, access controls, and continuous monitoring, thus preventing data loss,
         breaches, and compliance issues. Effective cloud security helps maintain the trust of users
          and stakeholders while supporting the overall integrity and availability of cloud-based
           resources.`,

  keyPoints: [
    {
      title: `Data Protection`,
      text: `Ensuring the confidentiality, integrity, and availability of data stored in the
cloud. This includes encryption (both in transit and at rest), data masking, and secure data
access controls.`,
      icon: blackShield,
    },
    {
      title: `Access Management`,
      text: `Implementing mechanisms to control who can access cloud resources and
data. This involves identity and access management (IAM) solutions, which use authentication
and authorization methods to ensure that only authorized users can access specific resources.`,
      icon: blackUser,
    },
    {
      title: `Threat Detection and Response`,
      text: `Monitoring cloud environments for signs of suspicious activity
or potential breaches. This includes real-time threat detection, incident response, and
automated alerts to quickly address security issues.`,
      icon: blackBug,
    },
    {
      title: `Compliance`,
      text: `Adhering to regulatory and industry standards related to cloud security. Different
industries and regions have specific compliance requirements, such as GDPR, HIPAA, and PCI-
DSS, which cloud providers and customers must follow.`,
      icon: blackTest,
    },
    {
      title: `Network Security`,
      text: `Protecting the network infrastructure that supports cloud services. This
involves securing network traffic, using firewalls, intrusion detection systems, and ensuring
secure network configurations to prevent unauthorized access.`,
      icon: blackGlobe,
    },
    {
      title: `Application Security`,
      text: `Securing applications that run in the cloud. This includes implementing
secure coding practices, conducting regular security assessments, and using application firewalls
to protect against vulnerabilities and attacks.`,
      icon: blackLock,
    },
    {
      title: `BCDR for Cloud`,
      text: `Ensuring that data is regularly backed up and that there are
plans in place to recover from data loss or system outages. Cloud providers typically offer
backup and recovery solutions to mitigate data loss.`,
      icon: blackData,
    },
    {
      title: `Security Policies and Governance`,
      text: `Establishing and enforcing security policies and governance
frameworks to manage and monitor security practices across cloud environments. This involves
setting guidelines, conducting audits, and ensuring compliance with security standards.`,
      icon: blackBuilding,
    },
    {
      title: `End-User Education`,
      text: `Training users on best practices for cloud security, including recognizing
phishing attempts, using strong passwords, and understanding the importance of data
protection.`,
      icon: blackBook,
    },
    {
      title: `Third-Party Security`,
      text: `Third-Party Security: Evaluating and managing the security of third-party services and vendors
integrated with cloud environments. This includes assessing the security posture of cloud service
providers and any other external partners.`,
      icon: blackLaptop,
    },
  ],
  stats: [
    {
      title: `Cloud Security Spending`,
      text: `According to Gartner, global spending on cloud security is expected to
reach $13.8 billion in 2024, reflecting a significant investment in safeguarding cloud
environments.`,
      icon: blackCoinCost,
    },
    {
      title: `Data Breaches in the Cloud`,
      text: `The 2024 Verizon Data Breach Investigations Report found that
45% of data breaches involved cloud environments, underscoring the need for effective cloud
security measures.`,
      icon: blackDataLoss,
    },
    {
      title: `Data Loss Risks`,
      text: `According to a report by IDC, 60% of organizations do not have a
comprehensive backup and disaster recovery plan for their cloud data, increasing the risk of
data loss in case of incidents.`,
      icon: blackAlert,
    },
  ],

  subtopics: [
    {
      title: `MCSS`,
      subtitle: `Managed Cloud Security Services`,
      text: `
  As businesses increasingly migrate to the cloud, safeguarding your cloud environments becomes
paramount. Our Managed Cloud Security Services (MCSS) provide a comprehensive approach to
securing your cloud infrastructure. We handle everything from configuration management to
continuous monitoring and threat response, ensuring that your cloud assets are protected against
evolving threats. Our team of experts employs advanced security tools and best practices to maintain
robust defenses, allowing you to leverage the full benefits of the cloud with peace of mind. Let us take
care of your cloud security so you can focus on driving innovation and growth.`,
    },
    {
      title: `SaaS`,
      subtitle: `Our SaaS Security Solutions`,
      text: `
  Software as a Service (SaaS) represents a model where software applications are hosted by a provider
and made available to users over the internet. Rather than installing and maintaining applications on
individual computers or servers, organizations can access and use these applications through a web
browser. This model offers significant benefits, including cost savings, scalability, and ease of access.`,
    },
    {
      title: `Cloud Threat Intelligence`,
      subtitle: `Stay Ahead of Emerging Threats with Cloud Threat Intelligence`,
      text: `
  Protect your cloud infrastructure from the latest cyber threats with our Cloud Threat Intelligence
services. We provide actionable insights and intelligence on emerging threats specific to cloud
environments, allowing you to proactively address vulnerabilities and adjust your security posture. By
analyzing global threat trends and monitoring threat actors, we help you anticipate and prepare for
potential risks. With our cloud threat intelligence, you gain a strategic advantage in defending against
sophisticated attacks and ensuring the security of your cloud-based assets.`,
    },
    {
      title: `24/7 Cloud Security Monitoring`,
      subtitle: `Achieve Continuous Cloud Protection with Our 24/7 Monitoring`,
      text: `
  Ensure your cloud infrastructure is safeguarded around the clock with our 24/7 Cloud Security
Monitoring services. Our dedicated security experts provide constant vigilance, monitoring your cloud
environments for any signs of suspicious activity or potential breaches. With advanced tools and a
proactive approach, we detect and respond to threats in real-time, minimizing the risk of data loss or
system compromise. Whether you're managing a multi-cloud environment or a single cloud platform,
our 24/7 monitoring services deliver reliable, continuous protection to keep your cloud operations
secure.`,
    },
    {
      title: `Open Source Cloud Security Solutions`,
      subtitle: `Flexible and Cost-Effective Cloud Security with Open Source Solutions`,
      text: `
 Leverage the power of open-source technology with our Open Source Cloud Security Solutions. We
utilize a range of cutting-edge, open-source tools to create a customizable and scalable cloud security
framework tailored to your specific needs. This approach not only offers significant cost savings but also
provides transparency and flexibility in your security strategy. Our open-source solutions enable real-
time threat detection, access control, and vulnerability management, ensuring robust protection for
your cloud environments. Experience adaptable cloud security that evolves with your business
requirements and technology landscape.`,
    },
  ],

  pitchTitle: "Fortify Your Cloud with our Industry Leading Security Solutions",

  pitch: `In the rapidly evolving digital landscape, securing your cloud infrastructure is more critical than ever. At
The Solutioners, we specialize in delivering cutting-edge cloud security solutions designed to
protect your data, applications, and business operations from emerging threats. Our comprehensive
services ensure that your cloud environments remain secure, compliant, and resilient, allowing you to
focus on what truly matters—growing your business.`,

  conclusion: `As organizations continue to embrace the cloud, adopting a proactive and comprehensive approach to
cloud security will not only mitigate risks but also enable businesses to leverage the full potential of
cloud technologies with confidence. By prioritizing cloud security, you protect your digital assets and set
the stage for innovation and success in a secure, resilient digital landscape.`,

  videoCaptions: `In an era where large quantities of data are being increasingly stored online, cyber threats targeting cloud-based servers are becoming increasingly more frequent and ever more sophisticated. Our company offers robust solutions for protecting your SaaS applications and cloud infrastructure. We guarantee the protection of your digital assets with our top-tier cloud security services. Our team of cybersecurity experts employs advanced technologies and proactive strategies to safeguard your sensitive information against unauthorized access, data loss, and malicious attacks. With 24/7, monitoring, rapid incident response, backup and disaster recovery, and customized security protocols, we ensure your business remains secure and compliant. Trust us to provide the peace of mind you need to focus on what you do best, while we handle your cloud security with precision and care.`,
  backgroundInfo: {
    coloringInfo: {
      type: "gradient",
      data: {
        type: "linear",
        angle: 130,
        colorInfo: [
          { color: "rgba(200, 255, 255, 1)", stop: "0" },
          { color: "rgba(0, 200, 255, 1)", stop: "25" },
          { color: "rgba(0, 50, 255, 1)", stop: "50" },
          { color: "rgba(0, 0, 100, 1)", stop: "100" },
        ],
      },
    },
    patternInfo: null,
    imageInfo: null,
    floorInfo: null,
  },
  images: [iconImage, iconImage2],
  backgroundInfo2: cloudGradient,
  heights: {
    desktop: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "600px",
      question3: "200px",
      grid: "2000px",
      tabMenu: "1200px",
      list: "800px",
      conclusion: "600px",
      pitch: "750px",
    },
    mobile: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "800px",
      question3: "150px",
      grid: "2000px",
      tabMenu: "750px",
      list: "800px",
      conclusion: "600px",
      pitch: "600px",
    },
  },
};
