import { VideoCaption } from "./VideoCaption";
import { VideoTitle } from "./VideoTitle";

export const VideoOverlay = ({ title }) => {
  return (
    <div
      className="overlay-container"
      style={{
        color: "white",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
        filter: "drop-shadow(0px 0px 1px white)",
      }}
    >
      <VideoTitle title={title} />
    </div>
  );
};
