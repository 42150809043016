import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { OverlayedVideoSection } from "../../sections/video_section/OverlayedVideoSection";
import { footerHeight } from "../../../global/tempVar";

export const VideoPage = ({
  title,
  content,
  headerHeight = window.innerHeight,
  logo,
  icon,
  slogan,
  mainHeight,
  videoSource,
  videoTitle,
  videoCaption,
  gradient,
}) => {
  return (
    <div className={`${title}-page page`}>
      <Header
        title={title}
        height={headerHeight}
        logo={logo}
        slogan={slogan}
        gradient={gradient}
        icon={icon}
      />
      <main
        className={`${title} main`}
        style={{
          width: "100vw",
          height: `${mainHeight}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        {/* Probably have one seperate background section between the header and the video so that it isn't an immediate transition later on, also fix prop drilling */}
        {videoSource !== null && videoSource !== undefined && <OverlayedVideoSection
          vidPosition={headerHeight}
          src={videoSource}
          title={videoTitle}
          caption={videoCaption}
        />}
        <section
          className="main-content-container"
          style={{
            position: "absolute",
            top: videoSource !== null && videoSource !== undefined ? headerHeight + window.innerHeight + "px" : headerHeight + "px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            height: videoSource !== null && videoSource !== undefined ? mainHeight - window.innerHeight + "px" : mainHeight + "px",
            width: "100%",
          }}
        >
          {content}
        </section>
      </main>
      <Footer height={window.innerHeight} />
    </div>
  );
};
