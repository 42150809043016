import React from "react";
import { animationHandler, generateAnimation } from "./animationFunctions";
import { styleBackground } from "./styleFunctions";

export const ImageIcon = ({ src, width, backgroundInfo, animationInfo }) => {
  return (
    <div
      style={
        backgroundInfo !== null 
          ? {
              width: width,
              aspectRatio: "1 / 1",
              maskImage: `url(${src})`,
              maskSize: "100%",
              maskRepeat: "no-repeat",
              WebkitMaskImage: `url(${src})`,
              WebkitMaskSize: "100%",
              WebkitMaskRepeat: "no-repeat",
              // backdropFilter: "blur(5px)",
              animation: animationHandler(animationInfo),
              ...styleBackground(backgroundInfo),
            }
          : {
              width: width,
              aspectRatio: "1 / 1",
              backgroundImage: `url(${src})`,
              backgroundSize: "100% 100%",
              // backdropFilter: "blur(5px)",
              animation: animationHandler(animationInfo),
            }
      }
    ></div>
  );
};

/* !src.includes('3d') */
