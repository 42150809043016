import { useRef, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Accordion } from "./Accordion";
import { Container } from "../../sections/content/Container";
import { styleBackground } from "../../sections/content/styleFunctions";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

export const AccordionRow = ({
  item,
  rowHeight,
  isOpen,
  onClick,
  alwaysOpen,
  backgroundInfo,
  level,
}) => {
  const contentHeight = useRef();
  const device = useMediaQueries()

  const hasContent = (item) => {
    return Array.isArray(item.content) && item.content.length > 0;
  };

  return (
    <div
      className="accordion-row-wrapper"
      style={{
        width: "100%",
        height: rowHeight + item.height + "px",
        overflow: "hidden",
      }}
    >
      <div
        className="accordion-row-content-wrapper"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <button
          className={`item-title-container ${isOpen ? "active" : ""}`}
          onClick={onClick}
          style={{
            height: rowHeight + "px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 15 * (level + 1) + "px",
            cursor: hasContent(item) ? "pointer" : "default",
            zIndex: 10,
            color: "white",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0, 0.5)",
            ...styleBackground(backgroundInfo),
            //   backgroundColor: `rgba(10, 10, 20, ${Math.pow(0.5, level + 1)})`,
            // backgroundImage: `linear-gradient(90deg, rgba(30, 30, 40, ${Math.pow(
            //   0.8,
            //   level + 3
            // )}), rgba(10, 10, 20, ${Math.pow(0.8, level + 3)}))`,
            // backgroundImage:
            //   level > 0
            //     ? `linear-gradient(90deg, rgba(240, 240, 255, ${Math.pow(
            //         0.3,
            //         1.6 / level
            //       )}), rgba(255, 255, 255, ${Math.pow(0.3, 1.6 / level)}))`
            //     : `linear-gradient(90deg, rgba(30, 30, 40, ${Math.pow(
            //         0.8,
            //         level + 3
            //       )}), rgba(10, 10, 20, ${Math.pow(0.8, level + 3)}))`,
            // borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
          }}
        >
          <p
            className="content"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: device === 'desktop' ? "center" : 'flex-start',
              fontSize: "30px",
              textShadow: "0px 0px 10px rgba(0 0 0 / 0.5)",
            }}
          >
            {item.title}
          </p>
          {hasContent(item) && (
            <div
              style={{
                position: "absolute",
                right: "0px",
                width: "70px",
              }}
            >
              <RiArrowDropDownLine
                className={`dropdown-arrow ${isOpen ? "active" : ""}`}
              />
            </div>
          )}
        </button>
        {isOpen && hasContent(item) && (
          <div
            className="content-container"
            style={{
              width: "100%",
              zIndex: 5,
            }}
          >
            {/* <Accordion
              subMenu={true}
              content={item.content}
              width={"100%"}
              alwaysOpen={alwaysOpen}
              level={level + 1}
            /> */}
            <Container
              type={"superSection"}
              height={item.height}
              width={"100%"}
              style={item.style}
              index={0}
              contentData={item.content}
            />
          </div>
        )}
      </div>
    </div>
  );
};
