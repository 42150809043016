import { NavLink } from "react-router-dom";
import { centerItems, styleBackground } from "./styleFunctions";
import { generateAnimation } from "./animationFunctions";

export const ButtonLink = ({
  name,
  text,
  height,
  width,
  backgroundInfo,
  style,
  link,
  onClick,
  fontSize
}) => {
  return (
    <NavLink
      to={link}
      className={`${name}-button`}
      style={{
        boxShadow:
          style === "floating" ? "rgba(0, 0, 100, 0.2) 0px 0px 15px 1px" : "",
        borderRadius: "5px",
        border: "none",
        height: height,
        width: width,
        color: "white",
        fontSize: fontSize,
        textShadow: '0px 0px 10px rgba(0 0 0 / 0.5)',
        ...styleBackground(backgroundInfo),
        ...centerItems,
      }}
      onClick={onClick}
    >
      <p>{text}</p>
    </NavLink>
  );
}; //Styles = Floating, flat, neumorphism, also this should be for multiple buttons as well with a spacer, that's what the content should be, an array of buttons
