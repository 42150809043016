import { Scroller } from "../content/Scroller";

export const VideoCaption = ({
  caption,
  duration,
  visibility,
  started,
  paused,
}) => {
  return (
    <div
      className="summary container"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        position: "absolute",
        zIndex: 10,
      }}
    >
      <div
        className={`summary wrapper container ${visibility}`}
        style={{
          height: "25%",
          width: "85%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={`summary line ${visibility} `}
          style={{
            height: "27.5%",
            width: "85%",
            borderTop: "1px solid white",
            position: "absolute",
            maskImage:
              "linear-gradient(90deg, rgba(0, 0, 0, 0.25), black 25%, black 75%, rgba(0, 0, 0, 0.25))",
          }}
        ></div>
        <Scroller
          baseHeight={Math.round(window.innerHeight * 0.25)}
          baseWidth={Math.round(window.innerWidth * 0.85)}
          text={caption}
          duration={duration}
          started={started}
          paused={paused}
        />
      </div>
    </div>
  );
};
{
  /* <div
          className={`summary text-wrapper ${visibility}`}
          style={{
            height: "50%",
            width: "100%",
            display: "table",
            fontSize: "200%",
            borderTop: "1px solid white",
          }}
        > */
}
{
  /* <p
            className={`summary text-box-wrapper ${visibility}`}
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            {caption}
          </p> */
}
{
  /* </div> */
}
