import { ScrollingGallery } from "../../../components/layout/sections/ScrollingGallery";
import { Container } from "../../../components/sections/content/Container";
import {
  contentStyles,
  homeGradient,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
} from "../../../components/sections/content/styles";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

export const HomeContent = ({ mainHeight }) => {
  const device = useMediaQueries();

  const section1Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "0%",
      contentData: {
        type: "subtitle",
        text: `In today’s digital landscape, robust cybersecurity is essential for the success and continuity of any
  business. As cyber threats become increasingly frequent and sophisticated, safeguarding sensitive data
  and implementing comprehensive security measures are paramount.`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: ["cybersecurity"],
        continuous: false,
        backgroundInfo: homeGradient,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const section2Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "50%",
      contentData: {
        type: "title",
        text: `Who are we ?`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: ["we"],
        continuous: false,
        backgroundInfo: homeGradient,
        fontSize: device === "desktop" ? "48px" : "40px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "100%",
      contentData: {
        type: "subtitle",
        text: `We are a leading Canadian IT company committed to delivering cutting-edge cybersecurity solutions and
  technologies. Our mission is to provide you with the most advanced and effective security services to
  protect your business from evolving digital threats. With a focus on innovation and excellence, we
  leverage the latest advancements in cybersecurity to safeguard your sensitive information and ensure
  your operations remain secure.`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: [
          "Canadian IT company",
          // "mission",
          // "most advanced",
          "protect your business",
          // "operations remain secure",
        ],
        continuous: false,
        backgroundInfo: homeGradient,
        animationInfo: null,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const section3Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "10%",
      contentData: {
        type: "title",
        text: `What do we do ?`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "bottom",
        stylizedText: ["we do"],
        continuous: false,
        backgroundInfo: homeGradient,
        fontSize: device === "desktop" ? "48px" : "40px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "10%",
      contentData: {
        type: "subtitle",
        text: `We specialize in delivering a comprehensive suite of cybersecurity solutions
  tailored to meet the diverse needs of modern businesses. Our services include:`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: device === "desktop" ? "middle" : "bottom",
        stylizedText: ["wide variety", "many more services"],
        continuous: false,
        backgroundInfo: homeGradient,
        animationInfo: null,
        fontSize: device === "desktop" ? "36px" : "27px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "60%",
      contentData: {
        type: "list",
        listType: "unOrdered",
        fontSize: device === "desktop" ? "24px" : "16px",
        contentData: [
          {
            text: `Threat Detection: Advanced systems designed to identify and respond to potential security
  threats in real time, ensuring your defenses are always one step ahead.`,
          },
          {
            text: `Cloud Security: Robust solutions to protect your cloud environments, safeguarding your data
  and applications from unauthorized access and breaches.`,
          },
          {
            text: `Operational Technology Security: Specialized protection for industrial control systems and
  critical infrastructure, addressing the unique challenges of OT environments.`,
          },
          {
            text: `Security Operations Center (SOC) Services: Managed SOC services that provide continuous
  monitoring, threat analysis, and incident response to keep your organization secure around the
  clock.`,
          },
          {
            text: `Employee Awareness Training: Comprehensive training programs designed to educate and
  empower your team against cybersecurity threats, fostering a culture of security awareness.`,
          },
          {
            text: `System Integration Services: Expert integration of your various IT systems and technologies to
  enhance efficiency, performance, and security across your organization.`,
          },
        ],
      },
    },
  ];

  const section4Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: device === "desktop" ? "30%" : "15%",
      contentData: {
        type: "title",
        text: `Why choose us ?`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: device === "desktop" ? "middle" : "bottom",
        stylizedText: ["us"],
        continuous: false,
        backgroundInfo: homeGradient,
        fontSize: device === "desktop" ? "48px" : "40px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: device === "desktop" ? "70%" : "85%",
      contentData: {
        type: "subtitle",
        text: `Although we are a new entrant in the cybersecurity field, our impact is already being felt across the
  industry. Our team is exceptionally knowledgeable about the latest technological advancements,
  including artificial intelligence, and how they will shape the future of IT and cybersecurity.
  We pride ourselves on our ability to harness cutting-edge technologies to offer innovative solutions
  tailored to your specific needs. Our team of experienced IT professionals and cybersecurity analysts is
  dedicated to ensuring the utmost protection for your sensitive data and business information.
  Partner with us to experience a proactive and forward-thinking approach to cybersecurity. Join the
  growing number of businesses that have trusted us to secure their digital assets and navigate the
  evolving threat landscape with confidence.
      `,
        textColor: "white",
        xAlignment: "center",
        yAlignment: device === "desktop" ? "top" : "middle",
        stylizedText: [
          "Our team",
          "IT professionals",
          "Partner with us",
        ],
        continuous: false,
        backgroundInfo: homeGradient,
        animationInfo: null,
        fontSize: device === "desktop" ? "32px" : "24px",
      },
    },
  ];

  const section5Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "12%",
      contentData: {
        type: "title",
        text: `Our Valued Partnerships`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "bottom",
        stylizedText: ["Partnerships"],
        continuous: false,
        backgroundInfo: homeGradient,
        fontSize: device === "desktop" ? "48px" : "40px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "30%",
      contentData: {
        type: "subtitle",
        text: `At The Solutioners, we believe that collaboration is key to achieving exceptional results. We are
  proud to work with a diverse array of esteemed business partners who share our commitment to
  excellence in cybersecurity. Each partnership enhances our ability to deliver top-tier solutions and
  services tailored to meet the unique needs of our clients.`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: ["wide variety"],
        continuous: false,
        backgroundInfo: homeGradient,
        animationInfo: null,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "15%",
      contentData: {
        type: "title",
        text: `Explore Our Network`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "bottom",
        stylizedText: ["Our Network"],
        continuous: false,
        backgroundInfo: homeGradient,
        fontSize: device === "desktop" ? "48px" : "40px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "35%",
      contentData: {
        type: "subtitle",
        text: `We invite you to explore our network of trusted partners through the slideshow below. These
  partnerships are a testament to our dedication to providing comprehensive, cutting-edge cybersecurity
  solutions and our ability to collaborate effectively within the industry. Together, we are setting new
  standards in cybersecurity and driving innovation forward.`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: [
          // "our dedication",
          "cutting-edge cybersecurity",
          "driving innovation forward",
        ],
        continuous: false,
        backgroundInfo: homeGradient,
        animationInfo: null,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const homeContent = [
    {
      height: "400px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section1Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: "600px",
      style: superSectionStyles.blackDiagonalBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section2Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: "1100px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section3Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: "850px",
      style: superSectionStyles.blackDiagonalBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section4Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: device === "desktop" ? "1000px" : '900px',
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section5Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
  ];

  const generateContainers = (sectionInfo) => {
    return sectionInfo.map((section, index) => {
      return (
        <Container
          type={"superSection"}
          height={section.height}
          width={"100%"}
          style={section.style}
          index={index}
          contentData={section.contentData}
        />
      );
    });
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {generateContainers(homeContent)}
      <div
        style={{
          height: "10px",
          width: "100%",
          backgroundColor: "black",
          display: "flex",
        }}
      >
        {/* <div
          style={{
            height: "100%",
            width: "50%",
            backgroundColor: "grey",
            display: "flex",
          }}
        > */}
        {/* <Accordion
            height={"25%"}
            width={"100%"}
            alwaysOpen={true}
            rowHeight={50}
            content={[
              { title: "Item 1", content: [{ title: "1" }] },
              { title: "Item 2", content: [{ title: "1" }] },
              { title: "Item 3", content: [{ title: "1" }] },
            ]}
          /> */}
        {/* <Numbers targetNumber={1000} startingValue={0} duration={1000} /> */}
        {/* <Scroller /> */}
        {/* </div> */}
      </div>
      <ScrollingGallery height={200} />
    </div>
  );
};

{
  /* <ForegroundSection background={"linear-gradient(125deg, rgba(240,240,250,1), rgba(225,232,245,1))"} color1={'rgb(250,60,200)'} color2={'rgb(230,160,250)'}></ForegroundSection>
      <BackgroundSection background={"linear-gradient(45deg, hotpink, violet)"}/>
      <ForegroundSection background={"linear-gradient(125deg, rgba(225,232,245,1), rgba(210,225,240,1))"} color1={'rgb(250,60,200)'} color2={'rgb(230,160,250)'}/>
      <BackgroundSection background={"linear-gradient(-30deg, deepskyblue, violet, hotpink)"} />
      <ForegroundSection background={"linear-gradient(-45deg, violet, lavender)"} color1={'rgb(250,60,200)'} color2={'rgb(230,160,250)'}/>
      <BackgroundSection background={"linear-gradient(125deg, rgba(240,240,250,1), rgba(225,232,245,1))"} />
      <ForegroundSection background={"linear-gradient(-45deg, violet, lavender)"} color1={'rgb(150,200,220)'} color2={'rgb(200,230,250)'}/>
      <BackgroundSection background={"linear-gradient(-45deg, violet, lavender)"}/>
      <ForegroundSection background={"linear-gradient(-30deg, deepskyblue, violet, hotpink)"} color1={'rgb(150,200,220)'} color2={'rgb(200,230,250)'}/>
      <BackgroundSection background={"linear-gradient(-30deg, deepskyblue, violet, hotpink)"} />
      <ForegroundSection background={"linear-gradient(45deg, hotpink, violet)"} color1={'rgb(250,60,100)'} color2={'rgb(250,60,200)'}/>
      <BackgroundSection background={"linear-gradient(125deg, rgba(240,240,250,1), rgba(225,232,245,1))"} />
      <ForegroundSection background={"linear-gradient(-45deg, violet, lavender)"} color1={'rgb(250,60,100)'} color2={'rgb(250,60,200)'}/> */
}

// const sectionInfo = {type: 'stacked', contentInfo: [
//   { name: "1", height: 400, width: standardWidth, type: 'scroll', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "2", height: 1000, width: standardWidth, type: 'transition', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "3", height: 600, width: standardWidth, type: 'scroll', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "4", height: 300, width: standardWidth, type: 'transition', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "5", height: 800, width: standardWidth, type: 'scroll', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "6", height: 500, width: standardWidth, type: 'transition', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
// ]}; //Sections will be separate components as well, which will include contents and color info

//Use some metal gradients as well
/* 
"linear-gradient(45deg, hotpink, violet),
linear - gradient(-45deg, violet, lavender),
  linear - gradient(-30deg, deepskyblue, violet, hotpink),
  linear - gradient(-30deg, deepskyblue, violet, violet),
  linear-gradient(30deg, lavender, white, pink)"
*/
