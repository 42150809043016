export const VideoTitle = ({ title }) => {
  return (
    <div
      className="title container"
      style={{
        height: "66%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <header
        className="video-title"
        style={{
          height: "50%",
          width: "100%",
          display: "table",
          fontSize: '3.5cqh',
        }}
      >
        <h1
          style={{
            display: "table-cell",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          {title}
        </h1>
      </header>
    </div>
  );
};
