import React from "react";
import { centerItems } from "../content/styleFunctions";
import { NavLink } from "react-router-dom";

export const MobileNavButton = ({
  name,
  link,
  imageSource,
  imageDescription,
  loadPage,
  type,
  menuInfo,
}) => {
  const checkButtons = () => {
    if (link === "/") {
      if (name === "Home") {
        return "home";
      } else {
        return "about";
      }
    } else {
      return link;
    }
  };
  return (
    <div
      className="navLink-button container"
      style={{
        height: "4rem",
        width: "100%",
        ...centerItems,
      }}
    >
      <NavLink
        exact
        to={link}
        style={{
          height: "100%",
          width: "100%",
          borderBottom: "1px solid rgba(255,255,255,0.8)",
          filter: "drop-shadow(1px 1px 4px rgba(0,0,0, 0.24))",
          backdropFilter: "blur(9px)  brightness(100%)",
          fontSize: "30px",
          backgroundImage:
            "linear-gradient(90deg, rgba(1,1,1,0.8), rgba(1,1,1,0.7) 10%,  rgba(50,50,50, 0.65) 80%, rgba(50,50,50, 0.55) 90%, rgba(80,80,80, 0.45))",
          ...centerItems,
        }}
        onClick={() => loadPage(name)}
      >
        {name}
      </NavLink>
    </div>
  );
};
