import { NavLink } from "react-router-dom";
import { Accordion } from "../../../components/menus/accordion/Accordion";
import {
  antivirusGradient,
  bcdrGradient,
  cloudGradient,
  cyberGradient,
  incidentGradient,
  otGradient,
  penGradient,
  riskGradient,
  systemGradient,
  contentStyles,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
  apiGradient,
} from "../../../components/sections/content/styles";
import { Container } from "../../../components/sections/content/Container";
import { styleBackground } from "../../../components/sections/content/styleFunctions";
import apiIcon from "../../../assets/images/API_Security_Icon_Large.png";
import antivirusIcon from "../../../assets/images/Antivirus_Icon_Large.png";
import bcdrIcon from "../../../assets/images/BCDR_Icon_Large.png";
import cloudIcon from "../../../assets/images/Cloud_Security_Icon_Large.png";
import cyberIcon from "../../../assets/images/Cybersecurity_Icon_Large.png";
import incidentIcon from "../../../assets/images/Incident_Response_Icon_Large.png";
import otIcon from "../../../assets/images/Operational_Technology_Icon_Large.png";
import penIcon from "../../../assets/images/Penetration_Testing_Icon_Large.png";
import riskIcon from "../../../assets/images/Risk_Assessment_Icon_Large.png";
import systemIcon from "../../../assets/images/System_Integrators_Icon_Large.png";
import { useMediaQueries } from "../../../hooks/useMediaQueries";
import {} from "../../../components/sections/content/styles";

const options = [
  {
    title: "API Security",
    //Will also contain backgroundImage info
    //Also a button and more information as well
    contentHeight: "700px",
    subtitle: "Protect Your Connections",
    text: `Secure your APIs with our expert solutions that prevent breaches, ensure compliance, and maintain
system integrity. We keep your digital infrastructure safe, so you can focus on growing your business
with confidence.`,
    link: "/services/api_security",
    backgroundInfo: apiGradient,
    icon: apiIcon,
  },
  {
    title: "Browser Based Antivirus",
    //Will also contain backgroundImage info
    //Also a button and more information as well
    contentHeight: "700px",
    subtitle: "BDR and Browser-Based Antivirus Software",
    text: `Advanced Browser Detection and Response (BDR)
and antivirus solutions that protect against web-based threats. Our tools provide real-time
scanning, phishing protection, and malicious site blocking to enhance your browsing security.`,
    link: "/services/browser_based_antivirus",
    backgroundInfo: antivirusGradient,
    icon: antivirusIcon,
  },
  {
    title: "BCDR",
    contentHeight: "600px",
    subtitle: "BCDR Services",

    text: `Business Continuity and Disaster Recovery planning to ensure your operations
can withstand and recover from unexpected disruptions. Our services include strategy
development, testing, and implementation of recovery plans.`,
    link: "/services/bcdr",
    backgroundInfo: bcdrGradient,
    icon: bcdrIcon,
  },
  {
    title: "Cloud Security",
    contentHeight: "600px",
    subtitle: "Cloud Security Services",

    text: `Robust security solutions designed for cloud environments, ensuring
the confidentiality, integrity, and availability of your data. We provide cloud access security,
data protection, and compliance solutions to secure your cloud infrastructure.`,
    link: "/services/cloud_security",
    backgroundInfo: cloudGradient,
    icon: cloudIcon,
  },
  {
    title: "Incident Response",
    contentHeight: "600px",
    subtitle: "Incident Response Services",

    text: `Rapid response and management of security incidents to
minimize damage and recovery time. Our team provides expert guidance and support to
effectively handle and mitigate breaches and attacks.`,
    link: "/services/incident_response",
    backgroundInfo: incidentGradient,
    icon: incidentIcon,
  },
  {
    title: "MSSP",
    contentHeight: "600px",
    subtitle: "MSSP Services",

    text: `Protect your business with our Managed Security Service Provider (MSSP) solutions. We offer 24/7 monitoring, advanced threat detection, and rapid response to keep your data safe from evolving cyber threats. We tailor our approach to safeguard your digital assets against a wide array of cyber threats.`,
    link: "/services/mssp",
    backgroundInfo: cyberGradient,
    icon: cyberIcon,
  },
  {
    title: "OT Security",
    contentHeight: "600px",
    subtitle: "OT Security Services",

    text: `Protection for Operational Technology (OT) systems, including industrial
control systems and critical infrastructure. Our services focus on securing the technology that
supports essential operations and industrial processes.`,
    link: "/services/ot_security",
    backgroundInfo: otGradient,
    icon: otIcon,
  },
  {
    title: "Penetration Testing",
    contentHeight: "600px",
    subtitle: "Penetration Testing Services",

    text: `Simulated attacks to identify and address vulnerabilities in your
systems before real attackers can exploit them. We conduct comprehensive assessments to
strengthen your security defenses.`,
    link: "/services/penetration_testing",
    backgroundInfo: penGradient,
    icon: penIcon,
  },
  {
    title: "Risk Assessment",
    contentHeight: "600px",
    subtitle: "Cyber Risk Assessment",

    text: `A thorough evaluation of your organization’s risk exposure,
identifying vulnerabilities and potential threats. We deliver actionable insights to enhance
your risk management strategies and improve overall security posture.`,
    link: "/services/risk_assessment",
    backgroundInfo: riskGradient,
    icon: riskIcon,
  },
  {
    title: "System Integration",
    contentHeight: "600px",
    subtitle: "System Integration Services",

    text: `Expert integration of diverse systems and technologies to
streamline operations and enhance efficiency. We ensure that all components work
seamlessly together, optimizing performance and security.`,
    link: "/services/system_integrators",
    backgroundInfo: systemGradient,
    icon: systemIcon,
  },
];

export const ServicesContent = () => {
  // const generateContent = () => {
  //   let content = [];
  //   for (let i = 0; i < 5; i++) {
  //     const newContent = (
  //       <NavLink
  //         className={`card ${contentInfo.cardNames[i]}`}
  //         to={contentInfo.links[i]}
  //         style={{
  //           display: "table",
  //           backgroundImage: `url(${contentInfo.images[i]})`,
  //           backgroundSize: "400px 1000px",
  //         }}
  //         onClick={() => scrollToTop()}
  //       >
  //         <p
  //           style={{
  //             display: "table-cell",
  //             verticalAlign: "middle",
  //             textAlign: "center",
  //             filter: "drop-shadow(0px 0px 3px black)",
  //           }}
  //         >
  //           {contentInfo.cardNames[i]}
  //         </p>
  //       </NavLink>
  //     );
  //     content = [...content, newContent];
  //   }
  //   return content;
  // };

  const device = useMediaQueries();
  const section1Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "40%",
      contentData: {
        type: "title",
        text: `Are You Concerned About the Increasing Complexity of Cyber Threats ?`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "bottom",
        stylizedText: [],
        continuous: false,
        fontSize: device === "desktop" ? "48px" : "36px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "60%",
      contentData: {
        type: "subtitle",
        text: `We provide a full suite of cybersecurity solutions designed to address the complexities of modern
  threats. Our expert team utilizes cutting-edge technologies and proactive strategies to ensure your
  systems are protected against both known and emerging threats.`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: ["Canadian IT company", "cyber threats"],
        continuous: false,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const section2Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "50%",
      contentData: {
        type: "title",
        text: `Are You Struggling with Ensuring Security Across Diverse Environments?`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: [],
        continuous: false,
        fontSize: device === "desktop" ? "48px" : "36px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "50%",
      contentData: {
        type: "subtitle",
        text: `Managing security across multiple platforms—such as cloud environments, operational technology,
  and traditional IT infrastructure—can be overwhelming. How can you effectively protect all these
  different areas without compromising on quality or performance?`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: ["wide variety"],
        continuous: false,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const section3Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "30%",
      contentData: {
        type: "title",
        text: `Tailored Solutions for Every Environment`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "bottom",
        stylizedText: [],
        continuous: false,
        fontSize: device === "desktop" ? "48px" : "36px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "70%",
      contentData: {
        type: "subtitle",
        text: `Our services are designed to provide seamless security across all environments, ensuring
  comprehensive protection whether you’re operating on-premises, in the cloud, or within industrial
  settings. We address the unique challenges of each environment with specialized strategies and tools.
        `,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: [
          "well versed",
          "latest technologies",
          "AI",
          "IT professionals",
          "guarantee the protection",
        ],
        continuous: false,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const section4Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "12%",
      contentData: {
        type: "title",
        text: `Our Valued Partnerships`,
        textColor: "white",
        xAlignment: "center",
        yAlignment:  device === 'desktop' ? "middle": "middle",
        stylizedText: [],
        continuous: false,
        fontSize: device === "desktop" ? "48px" : "36px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: device === 'desktop' ? "20%" : '15%',
      contentData: {
        type: "subtitle",
        text: `At The Solutioners, we believe that collaboration is key to achieving exceptional results. We are
    proud to work with a diverse array of esteemed business partners who share our commitment to
    excellence in cybersecurity. Each partnership enhances our ability to deliver top-tier solutions and
    services tailored to meet the unique needs of our clients.`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: "top",
        stylizedText: ["wide variety"],
        continuous: false,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "20%",
      contentData: {
        type: "title",
        text: `By choosing our services, you benefit from`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: device === 'desktop' ? "middle" : "middle",
        stylizedText: [],
        continuous: false,
        fontSize: device === "desktop" ? "48px" : "36px",
      },
    },
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "30%",
      contentData: {
        type: "subtitle",
        text: `Up-to-Date Expertise: We stay ahead of evolving threats and new technologies to offer the
  most relevant and effective solutions.
  
   Customized Solutions: Our services are tailored to meet the specific needs of your
  organization, ensuring optimal protection and performance.
  
   Innovative Technology: We utilize advanced tools and AI to enhance our threat detection and
  response capabilities, providing superior security.
  
  Protect your organization with our cutting-edge cybersecurity solutions and experience the peace of
  mind that comes from knowing your digital assets are secure. Contact us today to learn more about
  how we can safeguard your business against the evolving landscape of cyber threats.`,
        textColor: "white",
        xAlignment: "center",
        yAlignment: "top",
        stylizedText: ["wide variety"],
        continuous: false,
        fontSize: device === "desktop" ? "36px" : "24px",
      },
    },
  ];

  const section5Content = [
    {
      type: "content",
      style: contentStyles.transparentBackgrounds.transparent,
      height: "100%",
      contentData: {
        type: "title",
        text: `We offer a range of specialized services to meet your cybersecurity needs`,
        textColor: "black",
        xAlignment: "center",
        yAlignment: "middle",
        stylizedText: [],
        continuous: false,
        fontSize: device === "desktop" ? "2.5cqw" : "36px",
      },
    },
  ];

  const content = [
    {
      height: "500px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section1Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: "500px",
      style: superSectionStyles.blackDiagonalBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section2Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: "600px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section3Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: device === 'desktop' ? "1500px" : "1300px",
      style: superSectionStyles.blackDiagonalBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section4Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
    {
      height: "200px",
      style: superSectionStyles.whiteFlatBackground3,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: section5Content,
            },
          ],
          divideLine: "",
        },
      ],
    },
  ];

  const generateContent = () => {
    return options.map((option) => {
      if (device !== "desktop") {
        return {
          title: option.title,
          height: "750px",
          style: superSectionStyles.whiteFlatBackgroundBottomShadow,
          content: [
            {
              type: "section",
              height: "100%",
              style: sectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "subsection",
                  height: "100%",
                  style: subsectionStyles.transparentBackgrounds.transparent,
                  contentData: [
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "50%",
                      contentData: {
                        type: "imageIcon",
                        src: option.icon,
                        width: "35%",
                        backgroundInfo: option.backgroundInfo,
                        animationInfo: {
                          name: "drop",
                          duration: 0.5,
                          timing: "ease-in-out",
                          direction: "normal",
                          delay: 0.1,
                        },
                      },
                    },
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "10%",
                      contentData: {
                        type: "title",
                        text: option.subtitle,
                        textColor: "black",
                        xAlignment: "center",
                        yAlignment: "bottom",
                        stylizedText: [],
                        continuous: false,
                        fontSize: '36px'
                      },
                    },
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "40%",
                      contentData: {
                        type: "subtitle",
                        text: option.text,
                        textColor: "rgba(55,55,55,1)",
                        xAlignment: "center",
                        yAlignment: "middle",
                        stylizedText: [],
                        continuous: false,
                        fontSize: '27px'
                      },
                    },
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "30%",
                      contentData: {
                        name: "hover",
                        type: "buttonLink",
                        text: "Tell me more \u2192",
                        buttonHeight: "50px",
                        buttonWidth: "250px",
                        backgroundInfo: option.backgroundInfo,
                        style: "floating",
                        link: option.link,
                        fontSize: "20px",
                        onClick: () => scrollToTop(),
                        // textColor: "black",
                      },
                    },
                  ],
                },
              ],
            },
          ],
          buttonBackgroundInfo: option.backgroundInfo,
        };
      } else {
        return {
          title: option.title,
          height: option.contentHeight,
          style: superSectionStyles.whiteFlatBackgroundBottomShadow,
          content: [
            {
              type: "section",
              height: "100%",
              style: sectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "subsection",
                  height: "90%",
                  style: subsectionStyles.transparentBackgrounds.transparent,
                  contentData: [
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "70%",
                      contentData: {
                        type: "imageIcon",
                        src: option.icon,
                        width: "70%",
                        backgroundInfo: option.backgroundInfo,
                        animationInfo: {
                          name: "slideRight",
                          duration: 0.5,
                          timing: "ease-in-out",
                          direction: "normal",
                          delay: 0.1,
                        },
                      },
                    },
                  ],
                },
                {
                  type: "subsection",
                  height: "90%",
                  style: subsectionStyles.transparentBackgrounds.transparent,
                  contentData: [
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "20%",
                      contentData: {
                        type: "title",
                        text: option.subtitle,
                        textColor: "black",
                        xAlignment: "left",
                        yAlignment: "bottom",
                        stylizedText: [],
                        continuous: false,
                        fontSize: "48px",
                      },
                    },
                    {
                      type: "content",
                      style: contentStyles.transparentBackgrounds.transparent,
                      height: "40%",
                      contentData: {
                        type: "subtitle",
                        text: option.text,
                        textColor: "rgba(55,55,55,1)",
                        xAlignment: "left",
                        yAlignment: "top",
                        stylizedText: [],
                        continuous: false,
                        fontSize: "36px",
                      },
                    },
                    {
                      type: "content",
                      style:
                        contentStyles.transparentBackgrounds.transparentLeft,
                      height: "20%",
                      contentData: {
                        name: "hover",
                        type: "buttonLink",
                        text: "Tell me more \u2192",
                        buttonHeight: "50px",
                        buttonWidth: "250px",
                        backgroundInfo: option.backgroundInfo,
                        style: "floating",
                        link: option.link,
                        fontSize: "20px",
                        onClick: () => scrollToTop(),
                        // textColor: "black",
                      },
                    },
                  ],
                },
              ],
            },
          ],
          buttonBackgroundInfo: option.backgroundInfo,
        };
      }
    });
  };

  const generateContainers = (sectionInfo) => {
    return sectionInfo.map((section, index) => {
      return (
        <Container
          type={"superSection"}
          height={section.height}
          width={"100%"}
          style={section.style}
          index={index}
          contentData={section.contentData}
        />
      );
    });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {generateContainers(content)}
      <section
        className="service-accordion-menu"
        style={{
          width: "100%",
          height: `${device === "desktop" ? 2500 : 1900}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Accordion
          rowHeight={device === "desktop" ? 175 : 100}
          width={"100%"}
          alwaysOpen={false}
          content={generateContent()}
        />
      </section>
    </section>
  );
};
