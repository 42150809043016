import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/BCDR_Logo.png";
import aboutVideo from "../../../assets/videos/BCDR.mp4";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { sectionData } from "./Content/bcdr";
import { bcdrGradient } from "../../../components/sections/content/styles";
import  icon  from "../../../assets/images/BCDR_Icon_Large.png";




//Next time just have a function which generate pages automatically and have react router send me to them, this way I wont have to waste time creating a new component page
const headerHeight = window.innerHeight;
const mainHeight = 9000;
const title = "BCDR Services";


export const BCDR = () => {
  return (
    <VideoPage
      title={"bcdr"}
      content={<ServiceSection sectionData={sectionData}/>}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={bcdrGradient}
    />
  );
};
