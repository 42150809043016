import { generateStyle, spaceContent } from "./styleFunctions";
import testBackgroundPattern from "../../../assets/images/diagonal_pattern.png";
import cloudBackground from "../../../assets/images/Cloud_Security_Background.jpg";
import systemBackground from "../../../assets/images/System_Integrator_Background.jpg";
import cyberBackground from "../../../assets/images/cybersecurity_background.jpg";
import antivirusBackground from "../../../assets/images/Antivirus_Background.jpg";
import apiBackground from "../../../assets/images/API_Background.jpg";
import otBackground from "../../../assets/images/Operational_Technology_Background.jpg";
import riskBackground from "../../../assets/images/Risk_Assessment_Background.jpg";
import bcdrBackground from "../../../assets/images/BCDR_Background.jpg";
import penTestBackground from "../../../assets/images/Penetration_Testing_Background.jpg";
import incidentBackground from "../../../assets/images/Incident_Response_Background.jpg";
import homeBackground from "../../../assets/images/Home_Background.jpg";
import servicesBackground from "../../../assets/images/Services_Background.jpg";
import contactBackground from "../../../assets/images/Contact_Background.jpg";
import { generateAnimation } from "./animationFunctions";

//Background gradients

export const transparent = {
  coloringInfo: {
    type: "color",
    data: "transparent",
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const white = {
  coloringInfo: {
    type: "color",
    data: "white",
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const purpleBlueGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 80,
      colorInfo: [
        { color: "rgba(235, 15, 245, 1)", stop: "0" },
        { color: "rgba(165, 15, 255, 1)", stop: "50" },
        { color: "rgba(55, 5, 255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const homeGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 30,
      colorInfo: [
        { color: "rgba(255,230,255,1)", stop: "0" },
        { color: "rgba(10,120,240)", stop: "25" },
        { color: "rgba(10,50,240, 0.95)", stop: "50" },
        { color: "rgba(180,0,250, 0.9)", stop: "75" },
        { color: "rgba(255, 80,170,0.8)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: homeBackground,
  },
  floorInfo: null,
};

export const servicesGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 30,
      colorInfo: [
        { color: "rgba(70,10,240)", stop: "0" },
        { color: "rgba(160,0,240, 0.95)", stop: "33" },
        { color: "rgba(255, 80,170,0.9)", stop: "67" },
        { color: "rgba(255,200,255, 0.75)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: servicesBackground,
  },
  floorInfo: null,
};

export const contactGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 30,
      colorInfo: [
        { color: "rgba(255, 0, 80, 1)", stop: "0" },
        { color: "rgba(255, 0, 170, 1)", stop: "30" },
        { color: "rgba(255,20,255, 0.8)", stop: "70" },
        { color: "rgba(160,120,240,0.7)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: contactBackground,
  },
  floorInfo: null,
};

export const apiGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(255, 100,0,1)", stop: "0" },
        { color: "rgba(255,150,0,0.99)", stop: "15" },
        { color: "rgba(200,250,0, 0.99)", stop: "50" },
        { color: "rgba(150,255,0, 0.9)", stop: "75" },
        { color: "rgba(0,155,0, 0.8)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: apiBackground,
  },
  floorInfo: null,
};

export const antivirusGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(255, 50,0,1)", stop: "0" },
        { color: "rgba(255,0,0,0.99)", stop: "15" },
        { color: "rgba(100,0,0, 0.99)", stop: "75" },
        { color: "rgba(50,0,0, 0.8)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: antivirusBackground,
  },
  floorInfo: null,
};

export const bcdrGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 60,
      colorInfo: [
        { color: "rgba(50, 205, 255, 1)", stop: "0" },
        { color: "rgba(0, 0, 255, 1)", stop: "20" },
        { color: "rgba(0, 20, 180, 1)", stop: "40" },
        { color: "rgba(0, 0, 100, 1)", stop: "70" },
        { color: "rgba(0, 0, 30, 0.6)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: bcdrBackground,
  },
  floorInfo: null,
};

export const cloudGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(200, 255, 255, 1)", stop: "0" },
        { color: "rgba(0, 200, 255, 1)", stop: "25" },
        { color: "rgba(0, 50, 255, 0.97)", stop: "50" },
        { color: "rgba(0, 0, 100, 0.85)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: cloudBackground,
  },
  floorInfo: null,
};

export const cyberGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(255, 0, 255, 1)", stop: "0" },
        { color: "rgba(255, 0, 155, 1)", stop: "35" },
        { color: "rgba(255, 0, 105, 0.85)", stop: "65" },
        { color: "rgba(255, 0, 55, 0.5)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: cyberBackground,
  },
  floorInfo: null,
};

export const incidentGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 60,
      colorInfo: [
        { color: "rgba(255, 225, 55, 1)", stop: "0" },
        { color: "rgba(255, 150, 0, 1)", stop: "30" },
        { color: "rgba(255, 25, 0, 0.8)", stop: "75" },
        { color: "rgba(255, 0, 155, 0.7)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: incidentBackground,
  },
  floorInfo: null,
};

export const otGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(100, 255, 0, 1)", stop: "0" },
        { color: "rgba(0, 225, 50, 1)", stop: "15" },
        { color: "rgba(0, 150, 40, 0.98)", stop: "40" },
        { color: "rgba(0, 20, 0, 0.93)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: otBackground,
  },
  floorInfo: null,
};

export const penGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(200, 255, 100, 1)", stop: "0" },
        { color: "rgba(0, 200, 255, 0.95)", stop: "55" },
        { color: "rgba(0, 100, 255, 0.9)", stop: "80" },
        { color: "rgba(0, 10, 250, 0.6)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: penTestBackground,
  },
  floorInfo: null,
};

export const riskGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(0, 0, 255, 1)", stop: "0" },
        { color: "rgba(105, 0, 255, 0.98)", stop: "25" },
        { color: "rgba(225, 0, 255, 0.95)", stop: "50" },
        { color: "rgba(255, 0, 105, 0.87)", stop: "75" },
        { color: "rgba(255, 0, 55, 0.75)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: riskBackground,
  },
  floorInfo: null,
};

export const systemGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 70,
      colorInfo: [
        { color: "rgba(0, 255, 205, 1)", stop: "0" },
        { color: "rgba(0, 205, 125, 1)", stop: "30" },
        { color: "rgba(0, 105, 65, 0.95)", stop: "60" },
        { color: "rgba(0, 65, 55, 0.9)", stop: "80" },
        { color: "rgba(0, 35, 5, 0.8)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: systemBackground,
  },
  floorInfo: null,
};

//background styles

const whiteGlass60 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 80,
      colorInfo: [
        { color: "rgba(245,250,255, 0.6)", stop: "0" },
        { color: "rgba(255,255,255, 0.6)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteGlass25 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 80,
      colorInfo: [
        { color: "rgba(245,250,255, 0.25)", stop: "0" },
        { color: "rgba(255,255,255, 0.25)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const blackGlass10 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(0, 0, 0, 0.1)", stop: "0" },
        { color: "rgba(5, 5, 15, 0.1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const blackGlass50 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(0, 0, 0, 0.5)", stop: "0" },
        { color: "rgba(5, 5, 15, 0.5)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteDiagonalGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(245,250,255, 0.5)", stop: "0" },
        { color: "rgba(210,215,255,0.5)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 10,
    color: "white",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const blackDiagonalGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(10, 10, 20, 1)", stop: "0" },
        { color: "rgba(30, 30, 40, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 10,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

export const lightDiagonalGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 90,
      colorInfo: [
        { color: "rgba(50, 50, 70, 1)", stop: "0" },
        { color: "rgba(80, 80, 100, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 70,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const whiteBlackGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(245,250,255, 0.95)", stop: "0" },
        { color: "rgba(210,220,255, 0.1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const whiteFlat = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 165,
      colorInfo: [
        { color: "rgba(245,250,255, 1)", stop: "0" },
        { color: "rgba(210,220,255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFlat2 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(252, 253, 255, 1)", stop: "0" },
        { color: "rgba(210, 215, 255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFlat3 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 45,
      colorInfo: [
        { color: "rgba(245, 250, 255, 1)", stop: "0" },
        { color: "rgba(210, 220, 255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFlat4 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 90,
      colorInfo: [
        { color: "rgba(245, 250, 255, 1)", stop: "0" },
        { color: "rgba(210, 220, 255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const whiteFlatBottomShadow = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 180,
      colorInfo: [
        { color: "rgba(245,250,255, 1)", stop: "0" },
        { color: "rgba(210,220,255, 1)", stop: "99" },
        { color: "rgba(170,170,180, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const blackFlat = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(10, 10, 20, 1)", stop: "0" },
        { color: "rgba(30, 30, 40, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFadedImage = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 165,
      colorInfo: [
        { color: "rgba(245,250,255, 0.99)", stop: "0" },
        { color: "rgba(210,220,255, 0.95)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: cloudBackground,
  },
  floorInfo: null,
};

const clearImage = {
  coloringInfo: {
    type: "color",
    data: "transparent",
  },
  patternInfo: null,
  imageInfo: {
    image: cloudBackground,
  },
  floorInfo: null,
};

export const greyLightDiagonal = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(50, 50, 70, 1)", stop: "0" },
        { color: "rgba(80, 80, 100, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 100,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

export const greyDarkDiagonal = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(10, 10, 25, 1)", stop: "0" },
        { color: "rgba(30, 30, 45, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 100,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const whiteFloor = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(0,0,0, 1)", stop: "0" },
        { color: "rgba(10,10,30, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: {
    height: "50%",
    backgroundInfo: {
      coloringInfo: {
        type: "gradient",
        data: {
          type: "linear",
          angle: 180,
          colorInfo: [
            { color: "rgba(245,250,255, 1)", stop: "0" },
            { color: "rgba(210,220,255, 1)", stop: "100" },
          ],
        },
      },
    },
  },
};

const clearBlackDiagonal = {
  coloringInfo: {
    type: "color",
    data: "transparent",
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 100,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const blackDiagonalBackground = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 125,
      colorInfo: [
        { color: "rgba(1, 1, 1, 1)", stop: "0" },
        { color: "rgba(15,15,25, 1)", stop: "100" },
      ],
    },
  },
  imageInfo: {
    bringToTop: true,
    image: testBackgroundPattern,
  },
  floorInfo: null,
};

//Style Packages

export const superSectionStyles = {
  blackDiagonalBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat",
        "hidden",
        "100%",
        "100%",
        true
      ),
      backgroundInfo: blackDiagonalBackground,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "90%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  parallaxImageBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "hidden",
        "100%",
        "100%",
        true,
        { zIndex: 1 }
      ),
      backgroundInfo: clearImage,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "90%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlat,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackground2: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlat2,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackground3: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlat3,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackgroundBottomShadow: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlatBottomShadow,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackgroundFull: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlat,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "100%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  glossyFloorBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "hidden",
        "100%",
        "100%",
        true
      ),
      backgroundInfo: whiteFloor,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "90%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
};

export const subsectionStyles = {
  transparentBackgrounds: {
    transparent: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false,
          spaceContent("space-evenly")
        ),
        backgroundInfo: transparent,
      },
    },
    transparentCard: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false,
          spaceContent("space-evenly")
        ),
        backgroundInfo: transparent,
      },
    },
  },
  window: {
    windowAside: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false,
          spaceContent("center")
        ),
        backgroundInfo: transparent,
      },
    },
    windowMain: {
      outer: {
        ...generateStyle(
          "outer",
          "right",
          "glass2",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: whiteFlat4,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat roundedRightCorners",
          "visible",
          "100%",
          "100%",
          false,
          spaceContent("center")
        ),
        backgroundInfo: transparent,
      },
    },
  },
};

export const contentStyles = {
  transparentBackgrounds: {
    transparent: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    transparent90: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackDiagonals: {
      outer: null,
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: clearBlackDiagonal,
      },
    },
    transparentCard: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    transparentRight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          "right",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    transparentLeft: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          "left",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
  flatBackgrounds: {
    doubleFlatLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "99%",
          "100%",
          false,
          { color: "white", zIndex: "10" }
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "hidden",
          "105%",
          "98%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
    },
    doubleFlatDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "99%",
          "100%",
          false,
          { color: "white", zIndex: "10" }
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "hidden",
          "105%",
          "98%",
          false
        ),
        backgroundInfo: blackFlat,
      },
    },
    darkCard: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: blackFlat,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackDiagonals: {
      outer: {
        ...generateStyle(
          //Might need to change this
          "outer",
          "center",
          "flat",
          "hidden",
          "100%",
          "100%",
          true
        ),
        backgroundInfo: blackDiagonalBackground,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
};

export const sectionStyles = {
  transparentBackgrounds: {
    transparent: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    transparentFull: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
  glassBackgrounds: {
    whiteDiagonalGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass4-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteDiagonalGlass,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackDiagonalGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass4-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackDiagonalGlass,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    whiteGlass60: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass3-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteGlass60,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    whiteGlass25: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass3-border-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteGlass25,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackGlass50: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass2-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass50,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackGlass10: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass2-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass10,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    //Make reflective versions as well
  },
  flatBackgrounds: {
    flatWhite: {
      outer: {
        ...generateStyle(
          //Might need to change this
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    fadedWhite: {
      outer: {
        ...generateStyle(
          //Might need to change this
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteFadedImage,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    overlappingFlatCenterLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "101%",
          "90%",
          false
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "102%",
          "97%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
    },
    overlappingFlatCenterDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "101%",
          "90%",
          false
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "102%",
          "97%",
          false
        ),
        backgroundInfo: blackFlat,
      },
    },
  }, //Mainly double flat
  windowBackgrounds: {
    darkGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "right",
          "glass2-border-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass50,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat roundedRightCorners",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    lightGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "right",
          "glass2-border-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass10,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "glass3",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
  frameBackgrounds: {
    centerDiagonalLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "95%",
          "95%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
    },
    cornerDiagonalLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "100%",
          "100%",
          false,
          { top: "10px", left: "10px" }
        ),
        backgroundInfo: whiteFlat,
      },
    },
    centerDiagonalDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "95%",
          "95%",
          false
        ),
        backgroundInfo: blackFlat,
      },
    },
    cornerDiagonalDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "100%",
          "100%",
          false,
          { top: "10px", left: "10px" }
        ),
        backgroundInfo: blackFlat,
      },
    },
  },
};

export const generateFadedImageBackgroundInfo = (image) => {
  //Keep it only to white right now
  return {
    coloringInfo: {
      type: "gradient",
      data: {
        type: "linear",
        angle: 165,
        colorInfo: [
          { color: "rgba(245,250,255, 0.99)", stop: "0" },
          { color: "rgba(210,220,255, 0.95)", stop: "100" },
        ],
      },
    },
    patternInfo: null,
    imageInfo: {
      image: image,
    },
    floorInfo: null,
  };
};

export const generateWhiteFadedBackground = (image) => {
  const backgroundInfo = generateFadedImageBackgroundInfo(image);
  return {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: backgroundInfo,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  };
};

/* 
// For SuperSection, background with image, pattern and or gradient, and can be parallax
// Put all of the superSections into one package(including floor), all content styles into one package, windows into one package, all double Matte into one package as well

 */
