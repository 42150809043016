import iconImage from "../../../../assets/images/Cybersecurity_Icon_Large.png";
import iconImage2 from "../../../../assets/images/shield3d.png";
import { cyberGradient } from "../../../../components/sections/content/styles";
import blackArrows from "../../../../assets/images/blackArrows.png";
import blackTest from "../../../../assets/images/blackTest.png";
import blackGear from "../../../../assets/images/blackGear.png";
import blackWarning from "../../../../assets/images/blackWarning.png";
import blackLaptop from "../../../../assets/images/blackLaptop.png";
import bcdr from "../../../../assets/images/BCDR_Icon_Large.png";
import blackUser from "../../../../assets/images/blackUser.png";
import blackShield from "../../../../assets/images/blackShield.png";
import blackBug from "../../../../assets/images/Threat_Detection_Icon.png";
import blackLock from "../../../../assets/images/blackLock.png";
import blackGlobe from "../../../../assets/images/blackGlobe.png";
import blackData from "../../../../assets/images/blackData.png";
import blackAlert from "../../../../assets/images/blackAlert.png";
import blackLightning from "../../../../assets/images/blackLightning.png";
import blackBuilding from "../../../../assets/images/Institution_Icon.png";
import blackBook from "../../../../assets/images/blackBook.png";
import blackRejection from "../../../../assets/images/Rejection_Icon.png";
import blackClock from "../../../../assets/images/Clock_Icon.png";
import otSecurity from "../../../../assets/images/Operational_Technology_Icon_Large.png";
import riskIcon from "../../../../assets/images/Risk_Assessment_Icon_Large.png";
import blackDataLoss from "../../../../assets/images/Data_Loss_Icon.png";
import blackMoney from "../../../../assets/images/Money_Icon.png";
import blackCoinCost from "../../../../assets/images/Rising_Expenses_Icon.png";

export const sectionData = {
  topic: "MSSP",

  definition: `MSSP (Managed Security Service Provider) focuses on outsourcing security management to enhance
organizational protection against threats. It offers a range of services, including continuous monitoring,
threat detection, and incident response, ensuring the confidentiality, integrity, and availability of critical
information.`,

  importance: `
  MSSPs are crucial for organizations looking to enhance their cybersecurity posture without the burden
of managing security internally. By providing 24/7 monitoring, threat detection, and incident response,
MSSPs enable businesses to stay ahead of evolving threats and ensure compliance with regulatory
standards​.`,

  keyPoints: [
    {
      title: `24/7 Monitoring`,
      text: `MSSPs provide round-the-clock surveillance of security systems to detect and respond to threats in real
time. This constant vigilance helps organizations quickly address potential vulnerabilities and incidents.`,
      icon: blackClock,
    },
    {
      title: `Threat Detection and Intelligence`,
      text: `MSSPs utilize advanced technologies and threat intelligence to identify and analyze potential security
threats, enabling proactive measures to prevent attacks before they occur.`,
      icon: riskIcon,
    },
    {
      title: `Incident Response`,
      text: `In the event of a security breach, MSSPs have established protocols for quickly managing and mitigating
the impact of incidents. This includes forensic analysis, containment strategies, and recovery processes.`,
      icon: blackWarning,
    },
    {
      title: `Operational Security`,
      text: `Involves the procedures and policies for handling and protecting data,
including how it is stored and shared. It also includes user access controls and other
administrative safeguards.`,
      icon: otSecurity,
    },
    {
      title: `Compliance Management`,
      text: `MSSPs help organizations meet regulatory requirements by ensuring that security measures are in place
and regularly updated. This includes reporting and documentation that demonstrate compliance with
standards such as GDPR, HIPAA, and PCI-DSS.`,
      icon: blackBuilding,
    },
    {
      title: `Security Awareness Training`,
      text: `MSSPs often provide training programs for employees to recognize and respond to security threats,
thereby strengthening the overall security posture of the organization.`,
      icon: blackUser,
    },
  ],

  stats: [
    {
      title: `Data Breach Concerns`,
      text: `Approximately 65% of board members feel their organization is at risk of a cyber-attack,
highlighting widespread concern among leadership regarding security threats​.`,
      icon: blackWarning
    },
    {
      title: `Growing Costs of Ransomware`,
      text: `The Cybersecurity Ventures Ransomware Report estimates that the
global cost of ransomware attacks will reach $30 billion by 2025, reflecting the growing financial
burden on businesses and individuals.`,
      icon: blackCoinCost
    },
    {
      title: `Cybersecurity Skills Gap`,
      text: `As cybersecurity threats evolve, the demand for MSSPs continues to rise, with over 3.5 million
open cybersecurity jobs projected by the end of 2023, underscoring a labor shortage in the
field.`,
      icon: blackLaptop
    },
  ],

  subtopics: [
    {
      title: `Open Source SOC`,
      subtitle: `Empower Your Security with Our Open Source SOC`,
      text: `
Unlock the power of transparency and flexibility with our Open Source Security Operations Center (SOC)
services. We leverage cutting-edge, open-source tools to build a tailored SOC that fits your unique
needs. This approach not only reduces costs but also provides you with a customizable and scalable
security solution. Our Open Source SOC offers real-time threat monitoring, analysis, and response, all
while maintaining transparency and control over your security operations. Experience a powerful,
adaptable SOC that evolves with your needs and delivers exceptional protection without compromising
on quality.`,
    },
    {
      title: `SOC 365`,
      subtitle: `Comprehensive Protection with SOC 365`,
      text: `
Ensure round-the-clock security with our SOC 365 services. Our fully managed Security Operations
Center operates 24/7 to provide you with continuous monitoring, threat detection, and incident
response. With SOC 365, you gain access to state-of-the-art technology and a team of skilled analysts
who are always on alert to identify and neutralize threats before they impact your business. Whether
you're a small business or a large enterprise, SOC 365 offers a robust and reliable security solution
designed to protect your organization every hour of every day.`,
    },
    {
      title: `Dark Web Monitoring Services`,
      subtitle: `Protect Your Business from Hidden Threats with Dark Web Monitoring`,
      text: `
The dark web is a hidden realm where stolen data and credentials are traded and sold. Our Dark Web
Monitoring Services provide you with early warning of potential breaches by scanning these clandestine
areas for compromised information related to your business. We continuously monitor and analyze dark
web activities to identify any threats that could jeopardize your security. With proactive alerts and
actionable insights, you can address vulnerabilities before they become serious issues, keeping your
business one step ahead of cyber-criminals.`,
    },
    {
      title: `Email Security Services`,
      subtitle: `Secure Your Communications with Comprehensive Email Security`,
      text: `
Email is a primary channel for communication, but it’s also a common target for cyber threats. Our Email
Security Services offer a multi-layered defense to protect your inbox from malicious attacks.

Phishing Protection: Combat deceptive phishing attempts with our advanced phishing protection
solutions. We employ sophisticated algorithms and real-time scanning to identify and block fraudulent
emails, reducing the risk of falling victim to scams.

Email Encryption: Safeguard sensitive information with our email encryption services. We ensure that
your communications are encrypted both in transit and at rest, preventing unauthorized access and
maintaining confidentiality.

Spam Filtering: Keep your inbox clutter-free and secure with our robust spam filtering. Our technology
effectively filters out unwanted and potentially harmful emails, enhancing your productivity and
reducing the risk of exposure to malware.

Protect your digital communication channels with our comprehensive email security solutions, designed
to keep your business safe from evolving email threats.`,
    },
  ],

  pitchTitle: "Stay Ahead of Threats with Our MSSP Solutions",

  pitch: `In an increasingly complex threat landscape, managing and maintaining your own security infrastructure
can be daunting. Our MSSP solutions offer a comprehensive
approach to protecting your business. We handle everything from threat detection to incident response,
ensuring that your systems are continuously monitored and protected. Our team of cybersecurity
experts operates around the clock, providing you with advanced security management, proactive threat
analysis, and seamless integration with your existing systems. Let us take the burden off your shoulders
so you can focus on growing your business, knowing your security is in expert hands.`,

  conclusion: `Choosing our MSSP and cybersecurity services is a vital step in
protecting your organization from the increasing threat landscape. Our MSSP solutions address the significant compliance challenges that 69% of organizations face,
providing expert guidance and support to ensure your business meets regulatory standards​. By prioritizing security with us, you can enhance customer trust, maintain operational continuity, and
stay ahead of cyber threats. Don't leave your security to chance—partner with us for comprehensive
MSSP and cybersecurity solutions that safeguard your business today.`,

  videoCaptions: `Our Managed Security Service Provider solutions are designed to keep you ahead of the curve.
Introducing our Open-Source SOC, where advanced threat detection meets cost-effective solutions
tailored to your unique business needs. With our SOC 365, you benefit from around-the-clock
monitoring by a dedicated team of experts who respond to threats in real time, allowing you to focus on
growing your business. Additionally, our email security safeguards your communications, protecting
sensitive data from phishing attacks and unauthorized access. By choosing our MSSP solutions, you’re
not just investing in security; you’re ensuring peace of mind. Secure your future with our comprehensive
services and contact us today to build a resilient security strategy that protects your organization every
day.`,

  backgroundInfo: {
    coloringInfo: {
      type: "gradient",
      data: {
        type: "linear",
        angle: 70,
        colorInfo: [
          { color: "rgba(255, 0, 255, 1)", stop: "0" },
          { color: "rgba(255, 0, 155, 1)", stop: "35" },
          { color: "rgba(255, 0, 105, 1)", stop: "65" },
          { color: "rgba(255, 0, 55, 1)", stop: "100" },
        ],
      },
    },
    patternInfo: null,
    imageInfo: null,
    floorInfo: null,
  },
  images: [iconImage, iconImage2],
  backgroundInfo2: cyberGradient,
  heights: {
    desktop: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "600px",
      question3: "200px",
      grid: "1500px",
      tabMenu: "1200px",
      list: "900px",
      conclusion: "600px",
      pitch: "750px",
    },
    mobile: {
      question: "250px",
      definition: "700px",
      question2: "250px",
      importance: "800px",
      question3: "150px",
      grid: "1700px",
      tabMenu: "900px",
      list: "900px",
      conclusion: "950px",
      pitch: "950px",
    },
  },
};
