import partners from "../../../assets/images/partners.png";
import background from "../../../assets/images/System_Integrator_Background.jpg";

export const ScrollingGallery = ({ height /* imageInfo */ }) => {
  return (
    <section
      style={{
        height: height + "px",
        width: "100%",
        backgroundImage: `linear-gradient(135deg, rgba(255,255,255, 1), rgba(215,225,255, 1) 60%, rgba(215,225,255, 0.95) 80%,  rgba(215,225,255,0.85)), url(${background})`,
        backgroundPosition: "center right",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        zIndex: 5
      }}
    >
      <div
        className="slideshow-container"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div
          className="slideshow"
          style={{
            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            className="images"
            style={{
              backgroundImage: `url(${partners})`,
              backgroundSize: "2578px 99px",
              // backgroundRepeat: "repeat-x",
              // backgroundPosition: "0% 0%",
              height: "99px",
              width: "100%",
              animation: "moveSlideshow 20s linear infinite",
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};
