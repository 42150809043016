const animationList = [
    { name: 'drop', infinite: false},
    { name: 'popUp', infinite: false},
    { name: 'slideLeft', infinite: false},
    { name: 'slideRight', infinite: false},
    { name: 'fadeIn', infinite: false},
    { name: 'fadeOut', infinite: false},
    { name: 'hover', infinite: false},
    { name: 'land', infinite: false},
    { name: 'grow', infinite: false},
    { name: 'dropDown', infinite: false},
    { name: 'rollUp', infinite: false},
    { name: 'float', infinite: true},
    { name: 'breathe', infinite: true},
    { name: 'rise', infinite: true},
    { name: 'appear', infinite: true},
    { name: 'collapse', infinite: true},
    { name: 'flipLeft', infinite: true},
    { name: 'flipRight', infinite: true},
    { name: 'flipUp', infinite: true},
    { name: 'flipDown', infinite: true},
    { name: 'spinClockWise', infinite: true},
    { name: 'spinCounterClockWise', infinite: true},
    { name: 'shake', infinite: true},
    { name: 'vibrate', infinite: true},
    { name: 'bounce', infinite: true},
    { name: 'dangle', infinite: true},
    { name: 'blink', infinite: true},
    { name: 'beat', infinite: true},
] //I will not sort this and will use a basic search for the time being

export const generateAnimation = (name, duration, timing = 'linear', direction = 'normal', delay = 0) => {
    let animation = ''
    animationList.forEach(animationProp => {
        if (animationProp.name === name) {
            animation = `${animationProp.name} ${duration}s ${timing} ${animationProp.infinite ? 'infinite' : 'forwards'} ${direction} ${delay}s`
        }
    })
    return animation
}

export const animationHandler = (animationInfo) => {
    const { name, duration, timing, direction, delay } = animationInfo || {};
    return generateAnimation(name, duration, timing, direction, delay);
}