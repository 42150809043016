import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/Risk_Logo.png";
import aboutVideo from "../../../assets/videos/Risk Assessment.mp4";
import { sectionData } from "./Content/riskAssessment";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { riskGradient } from "../../../components/sections/content/styles";
import icon from "../../../assets/images/Risk_Assessment_Icon_Large.png"

//Next time just have a function which generate pages automatically and have react router send me to them, this way I wont have to waste time creating a new component page
const headerHeight = window.innerHeight;
const mainHeight = 7300;
const title = "Risk Assessment Service";

export const RiskAssessment = () => {
  return (
    <VideoPage
      title={"risk assessment"}
      content={<ServiceSection sectionData={sectionData} />}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={riskGradient}
    />
  );
};
