import { NavLink } from "react-router-dom";

export const NavButton = ({
  link,
  name,
  imageSource,
  imageDescription,
  loadPage,
  type,
  menuInfo,
}) => {
  const checkButtons = () => {
    if (link === "/") {
      if (name === "Home") {
        return "home";
      } else {
        return "about";
      }
    } else {
      return link;
    }
  };
  return (
    <div
      className={`${
        type === "menu" ? "dropdown btn-container" : "btn-container"
      } ${checkButtons()}-button`}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        fontSize: "160%",
      }}
    >
      <NavLink
        exact
        to={link}
        className={`nav-button ${checkButtons()}-button`}
        style={(isActive) => ({
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          zIndex: 1000,
        })}
        onClick={() => loadPage(name)}
      >
        <img
          src={imageSource}
          alt={imageDescription}
          style={{ height: "50%", width: "22%" }}
        />
        <div className="button-text">{name}</div>
      </NavLink>
      {type === "menu" && (
        <div
          className="dropdown-container"
          style={{
            position: "relative",
            height: "40rem",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div className="dropdown-content">
            {menuInfo.map((info) => {
              return (
                <NavLink
                  exact
                  to={info.link}
                  style={{ display: " table" }}
                  onClick={() => loadPage(name)}
                >
                  <p
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    {info.name}
                  </p>
                </NavLink>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
