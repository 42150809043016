import { useMediaQuery } from "./useMediaQuery";

export const useMediaQueries = () => {
  const desktop = useMediaQuery("(orientation: landscape)")
  const tablet = useMediaQuery("(min-aspect-ratio: 8 / 9)");

  const checkDevice = () => {
    if (desktop) {
      return "desktop";
    } else if (tablet) {
      return "tablet";
    } else {
      return "phone";
    }
  };

  return checkDevice();
};
