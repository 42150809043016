import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/Pen_Test_Logo.png";
import aboutVideo from "../../../assets/videos/Penetration Testing.mp4";
import { sectionData } from "./Content/penetrationTesting";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { penGradient } from "../../../components/sections/content/styles";
import icon from "../../../assets/images/Penetration_Testing_Icon_Large.png"

//Next time just have a function which generate pages automatically and have react router send me to them, this way I wont have to waste time creating a new component page
const headerHeight = window.innerHeight;
const mainHeight = 8500;
const title = "Penetration Testing Service";

export const PenetrationTesting = () => {
  return (
    <VideoPage
      title={"penetration testing"}
      content={<ServiceSection sectionData={sectionData} />}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={penGradient}
    />
  );
};
