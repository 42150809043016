import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/Antivirus_Logo.png";
import aboutVideo from "../../../assets/videos/Antivirus Software Service.mp4";
import { sectionData } from "./Content/antivirus";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";
import { antivirusGradient } from "../../../components/sections/content/styles";
import  icon  from "../../../assets/images/Antivirus_Icon_Large.png";

const headerHeight = window.innerHeight;
const mainHeight = 8000;
const title = "Our BDR Antivirus Solution";

export const Antivirus = () => {
  return (
    <VideoPage
      title={"antivirus"}
      content={<ServiceSection sectionData={sectionData}/>}
      headerHeight={headerHeight}
      logo={logo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={antivirusGradient}
    />
  );
};
