import { useScrollPosition } from "../../hooks/useScrollPosition";
import {
  centerItems,
  styleBackground,
} from "../sections/content/styleFunctions";
import mainLogo from "../../assets/images/Main_Logo.png";
import { useMediaQueries } from "../../hooks/useMediaQueries";

const scrollMultipliers = [
  -0.5, -0.2, -0.7, -0.3, -0.6, -0.4, -0.8, -0.33, -0.25,
];

export const Header = ({ title, logo, slogan, gradient, icon }) => {
  const pos1 = useScrollPosition(scrollMultipliers[0]);
  const pos2 = useScrollPosition(scrollMultipliers[1]);
  const pos3 = useScrollPosition(scrollMultipliers[2]);
  const pos4 = useScrollPosition(scrollMultipliers[3]);
  const pos5 = useScrollPosition(scrollMultipliers[4]);
  const pos6 = useScrollPosition(scrollMultipliers[5]);
  const pos7 = useScrollPosition(scrollMultipliers[6]);
  const pos8 = useScrollPosition(scrollMultipliers[7]);
  const pos9 = useScrollPosition(scrollMultipliers[8]);
  const device = useMediaQueries();

  const setMainIcon = (title, iconURL) => {
    let mainIcon = (
      <div
        className="header-logo-icon"
        style={{
          height: "100%",
          aspectRatio: "1 / 1",
          backgroundColor: "white",
          maskImage: `url(${iconURL})`,
          maskSize: "100%",
          maskRepeat: "no-repeat",
          WebkitMaskImage: `url(${iconURL})`,
          WebkitMaskSize: "100%",
          WebkitMaskRepeat: "no-repeat",
          opacity: "100%",
        }}
      ></div>
    );
    if (title === "home") {
      mainIcon = (
        <div
          className="header-logo-icon"
          style={{
            height: "100%",
            aspectRatio: "1 / 1",
            backgroundColor: "white",
            maskImage: `url(${mainLogo})`,
            maskSize: "100%",
            maskRepeat: "no-repeat",
            WebkitMaskImage: `url(${mainLogo})`,
            WebkitMaskSize: "100%",
            WebkitMaskRepeat: "no-repeat",
            opacity: "100%",
          }}
        ></div>
      );
    }
    return mainIcon;
  };

  const generateBackgroundIcons = (title, iconURL) => {
    const positions = [pos1, pos2, pos3, pos4, pos5, pos6, pos7, pos8, pos9];
    return positions.map((position, i) => {
      const size = 150;
      // 50 +
      // (i / 8) * 200 +
      // (i % 7) * 50 * scrollMultipliers[positions.length - 1 - i] * -1 +
      // 200 * (8 / i);
      if (title === "home") {
        return (
          <div
            className="background-icon"
            style={{
              height: (size * -1) / scrollMultipliers[i] + "px",
              width: (size * -1) / scrollMultipliers[i] + "px",
              backgroundImage: `linear-gradient(rgba(255, 255, 255, ${-scrollMultipliers[
                i
              ]}), transparent)`,
              maskImage: `url(${mainLogo})`,
              maskSize: "100%",
              maskRepeat: "no-repeat",
              WebkitMaskImage: `url(${mainLogo})`,
              WebkitMaskSize: "100%",
              WebkitMaskRepeat: "no-repeat",
              position: "absolute",
              animation: `float ${
                -scrollMultipliers[i] + 1
              }s ease-in-out alternate infinite, fadeIn ${
                1.5 - scrollMultipliers[i] * 2
              }s forwards ease-in-out`,
              top:
                100 +
                ((1 + i) % 3) * 160 +
                ((1 + i) % 2) * 70 -
                ((1 + i) % 4) * 70 +
                ((1 + i) % 5) * 25 +
                position +
                "px",
              left: 20 + ((1 + i) % 8) * 300 - (8 / (i + 1)) * 60 + "px",
              zIndex: Math.floor((1 + scrollMultipliers[i]) * -10),
              backdropFilter: "blur(5px)",
            }}
          ></div>
        );
      } else {
        return (
          <div
            className="background-icon"
            style={{
              height: (size * -1) / scrollMultipliers[i] + "px",
              width: (size * -1) / scrollMultipliers[i] + "px",
              backgroundImage: `linear-gradient(rgba(255, 255, 255, ${-scrollMultipliers[
                i
              ]}), transparent)`,
              maskImage: `url(${iconURL})`,
              maskSize: "100%",
              maskRepeat: "no-repeat",
              WebkitMaskImage: `url(${iconURL})`,
              WebkitMaskSize: "100%",
              WebkitMaskRepeat: "no-repeat",
              position: "absolute",
              animation: `float ${
                -scrollMultipliers[i] + 1
              }s ease-in-out alternate infinite, fadeIn ${
                2 - scrollMultipliers[i] * 2
              }s forwards ease-in-out`,
              top:
                100 +
                ((1 + i) % 3) * 160 +
                ((1 + i) % 2) * 70 -
                ((1 + i) % 4) * 70 +
                ((1 + i) % 5) * 25 +
                position +
                "px",
              left: 20 + ((1 + i) % 8) * 300 - (8 / (i + 1)) * 60 + "px",
              zIndex: Math.floor((1 + scrollMultipliers[i]) * -10),
              backdropFilter: "blur(4px)",
            }}
          ></div>
        );
      }
    });
  };

  return (
    <header
      className={`${title}-header`}
      style={{
        width: "100%",
        height: `${window.innerHeight}px`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "100%",
        fontSize: "250px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        boxShadow: "inset 0px -5px 8px 1px rgba(0,0,0,0.1)",
        position: "relative",
        zIndex: "5",
        overflow: "hidden",
        ...styleBackground(gradient),
      }}
    >
      <div
        style={
          device === "desktop"
            ? {
                position: "absolute",
                height: "100px",
                width: "350px",
                fontSize: "2rem",
                top: "5rem",
                left: "1rem",
              }
            : {
                position: "absolute",
                height: "100px",
                width: "100%",
                fontSize: "2rem",
                top: "3rem",
                ...centerItems,
              }
        }
      >
        Need Help? Call 1-647-524-3894
      </div>
      <div className={`halftone ${title}`}></div>
      {generateBackgroundIcons(title, icon)}
      {
        <div
          className="logo-container"
          style={{
            width: "60%",
            height: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          {/* Have larger versions of the navbar button icons for the services and contact icons */}
          <div
            className="icon container"
            style={{
              width: device === "desktop" ? "20%" : "70%",
              aspectRatio: "1 / 1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {setMainIcon(title, icon)}
          </div>
          {device === "desktop" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "80%",
                aspectRatio: "1 / 1",
              }}
            >
              <img
                className="header-logo"
                src={logo}
                alt="Logo"
                style={{ width: "90%", aspectRatio: " 4.5 / 1" }}
              />
              {slogan && (
                <img
                  className="header-slogan"
                  src={slogan}
                  alt="Logo"
                  style={{ width: "90%", aspectRatio: " 17 / 1" }}
                />
              )}
            </div>
          )}
        </div>
      }
    </header>
  );
};
