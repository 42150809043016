import { ServicesContent } from "../Content/Services/ServicesContent";
import { VideoPage } from "../../components/layout/sections/VideoPage";
import servicesLogo from "../../assets/images/Services_Logo.png";
import servicesSlogan from "../../assets/images/Services_Slogan.png";
import servicesVideo from "../../assets/videos/Introduction to Our Services.mp4";
import { servicesGradient } from "../../components/sections/content/styles";
import icon from "../../assets/images/Services_Icon_Large.png";

//Put all of these into their separate JSON variables file
const headerHeight = window.innerHeight;
const mainHeight = 6500;
const servicesTitle = "What We Offer";
const servicesSummary = `Thank you for taking an interest in the various services that we have to offer. At The Solutioners,
   our goal is to provide you with the most robust cybersecurity solutions that are currently available.
   Our team of experts ensures your data will remain safe from every form of cyber threat. We specialize
    in protecting your digital assets through our comprehensive security solutions. We provide a large
    variety of services for you to choose from. These include a multitude of cybersecurity resources, to
    help identify and prevent potential risks before they can cause harm. A team of system integrators,
     responsible for implementing various technologies and networks into one seamless system. Plenty of
      cloud security services to guard and back up all of your online data. Various operational technology
       security services to help prevent the unauthorized access of industrial control systems. As well as
        a browser-based antivirus software to protect your device from malicious internet files.
        Additionally, we provide incident response services to manage and resolve security breaches swiftly.
         Our team is available 24/7 to support you in any emergency. You can trust us to safeguard your
         digital environment with our bulletproof solutions.`;
// const contentInfo = {
//   cardNames: [
//     "Cybersecurity",
//     "System Integrator",
//     "Cloud Security",
//     "Browser Based Antivirus",
//     "Operational Technology",
//   ],
//   images: [
//     cyberBackground,
//     systemBackground,
//     cloudBackground,
//     antivirusBackground,
//     operationsBackground,
//   ],
//   links: [
//     './cybersecurity',
//     './system_integrator',
//     './cloud_security',
//     './browser_based_antivirus',
//     './operational_technology',
//   ]
// };

export const Services = () => {
  return (
    <VideoPage
      title={"services"}
      content={<ServicesContent />}
      headerHeight={headerHeight}
      slogan={servicesSlogan}
      logo={servicesLogo}
      icon={icon}
      mainHeight={mainHeight}
      videoSource={servicesVideo}
      videoTitle={servicesTitle}
      videoCaption={servicesSummary}
      gradient={servicesGradient}
    />
  );
};
