import logo from "../../../assets/images/the_solutioners_full_logo_white.png";
import menuIcon from "../../../assets/images/Menu_Icon.png";
import { useRef, useState } from "react";
import { NavButton } from "./NavButton";
import { NavLink } from "react-router-dom";
import { centerItems } from "../content/styleFunctions";
import { MobileNavButton } from "./MobileNavButton";

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "600px",
  // overflow: "hidden",
};

export const Navbar = ({ btnInfo, type }) => {
  const currentPage = useRef("Home");
  const [showing, setShowing] = useState(false);
  const loadPage = (btnName) => {
    if (currentPage.current !== btnName) {
      if (btnName !== "About") {
        currentPage.current = btnName;
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, window.innerHeight);
      }
    } else {
      window.scrollTo(0, 0); //This doesn't work, might have to use useScrollPosition instead, also it loads for the other pages that are not Home, which I don't want
    }
    if (type === "phone" && showing) {
      setShowing(() => false);
    }
  };

  const generateNavButtons = (type) => {
    const navButtons = btnInfo.map((btn) => {
      if (type === "phone") {
        return (
          <MobileNavButton
            link={btn.link}
            name={btn.name}
            loadPage={loadPage}
            // imageSource={btn.imageURL}
            // imageDescription={btn.imageDescription}
            // type={btn.type}
            // menuInfo={btn.menuInfo !== undefined ? btn.menuInfo : undefined}
          ></MobileNavButton>
        );
      } else {
        return (
          <NavButton
            link={btn.link}
            name={btn.name}
            imageSource={btn.imageURL}
            imageDescription={btn.imageDescription}
            loadPage={loadPage}
            type={btn.type}
            menuInfo={btn.menuInfo !== undefined ? btn.menuInfo : undefined}
          ></NavButton>
        );
      }
    });
    return navButtons;
  };

  const formatNavButtons = (type) => {
    let buttons = <></>;
    if (type !== "desktop") {
      buttons = (
        <div
          className="nav-button container"
          style={{
            height: "4rem",
            width: "4rem",
            ...centerItems,
          }}
        >
          <div
            style={{
              height: "70%",
              width: "70%",
              maskImage: `url(${menuIcon})`,
              maskSize: "100%",
              maskRepeat: "no-repeat",
              WebkitMaskImage: `url(${menuIcon})`,
              WebkitMaskSize: "100%",
              WebkitMaskRepeat: "no-repeat",
              backgroundColor: "white",
            }}
            onClick={() => setShowing((prev) => !prev)}
          ></div>
        </div>
      );
    } else {
      buttons = (
        <div className="nav-button container" style={buttonContainerStyle}>
          {generateNavButtons(type)}
        </div>
      );
    }
    return buttons;
  };

  return (
    <nav
      className="navbar container"
      style={{
        position: "fixed",
        top: "0px",
        width: "100%",
        height: "4rem",
        zIndex: 1000,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "4rem",
          width: "100%",
          display: "flex",
          zIndex: 10,
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.8)",
          filter: "drop-shadow(1px 1px 4px rgba(0,0,0, 0.24))",
          backdropFilter: "blur(10px)  brightness(100%)",
          backgroundImage:
            "linear-gradient(90deg, rgba(1,1,1,1), rgba(1,1,1,0.9) 10%,  rgba(50,50,50, 0.85) 80%, rgba(50,50,50, 0.85) 90%, rgba(80,80,80, 0.65))",
          ...centerItems,
        }}
      >
        <div
          style={{
            height: "100%",
            width: "98%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            logo-spacer
            style={{
              height: "100%",
              width: "220px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <NavLink
              exact
              to={"/"}
              className="logo-container"
              style={{ height: "60%", width: "100%" }}
              onClick={() => loadPage("Home")}
            >
              <img src={logo} alt="Logo" style={{ height: "100%" }} />
            </NavLink>
          </div>
          {formatNavButtons(type)}
        </div>
      </div>
      {type === "phone" && showing && (
        <div
          className="dropdown container"
          style={{
            width: "100%",
            color: "white",
            flexDirection: "column",
            zIndex: -100,
            overflow: "hidden",
            ...centerItems,
          }}
        >
          <div
            className="main-dropdown"
            style={{
              width: "100%",
              color: "white",
              flexDirection: "column",
              ...centerItems,
            }}
          >
            {generateNavButtons(type)}
          </div>
        </div>
      )}
    </nav>
  );
};
