import { FadingVideoSection } from "./FadingVideoSection";
import { useScrollPosition } from "../../../hooks/useScrollPosition";
import { useEffect, useRef } from "react";
import { VideoOverlay } from "./VideoOverlay";

export const OverlayedVideoSection = ({
  src,
  vidPosition,
  title,
  caption,
  fadeLimit = 0.5,
}) => {
  const pos = useScrollPosition(1);
  const summaryVisibility = useRef("hidden");
  const getOpacity = () => {
    let distance = Math.abs(vidPosition - pos) / window.innerHeight;
    if (distance >= fadeLimit) {
      distance = fadeLimit;
      if (summaryVisibility.current !== "hidden") {
        summaryVisibility.current = "hidden";
      }
    } else if (distance === 0) {
      if (summaryVisibility.current !== "showing") {
        summaryVisibility.current = "showing";
      }
    }
    return 1 - distance * (1 / fadeLimit);
  };

  const handleVideo = () => {
    if (
      summaryVisibility.current === "hidden" &&
      pos >= vidPosition - window.innerHeight / 7.5 &&
      pos <= vidPosition + window.innerHeight / 8.5
    ) {
      summaryVisibility.current = "showing";
    }
  };

  useEffect(() => {
    handleVideo();
  });
  return (
    <div
      className="overlayed-video-container"
      style={{
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: vidPosition + "px",
        opacity: getOpacity(),
      }}
    >
      <FadingVideoSection
        source={src}
        repeat={false}
        fadeInTime={0.5}
        fadeOutTime={0.5}
        vidPosition={vidPosition}
        fadeLimit={fadeLimit}
        caption={caption}
        visibility={summaryVisibility.current}
      />
      <VideoOverlay title={title} />
    </div>
  );
};

/*
TodoList:
// -Get the title to fade in first, 
// -then get the video
// -then finally the summary, also summary should disappear when scrolled away
*/
