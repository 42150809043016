import iconImage from "../../../../assets/images/API_Security_Icon_Large.png";
import iconImage2 from "../../../../assets/images/key3d.png";
import { apiGradient } from "../../../../components/sections/content/styles";
import blackArrows from "../../../../assets/images/blackArrows.png";
import blackTest from "../../../../assets/images/blackTest.png";
import blackGear from "../../../../assets/images/blackGear.png";
import blackWarning from "../../../../assets/images/blackWarning.png";
import blackLaptop from "../../../../assets/images/blackLaptop.png";
import bcdr from "../../../../assets/images/BCDR_Icon_Large.png";
import blackUser from "../../../../assets/images/blackUser.png";
import blackShield from "../../../../assets/images/blackShield.png";
import blackBug from "../../../../assets/images/Threat_Detection_Icon.png";
import blackLock from "../../../../assets/images/blackLock.png";
import blackGlobe from "../../../../assets/images/blackGlobe.png";
import blackData from "../../../../assets/images/blackData.png";
import blackAlert from "../../../../assets/images/blackAlert.png";
import blackLightning from "../../../../assets/images/blackLightning.png";
import blackBuilding from "../../../../assets/images/Institution_Icon.png";
import blackBook from "../../../../assets/images/blackBook.png";
import blackMeter from "../../../../assets/images/blackMeter.png";
import blackGraph from "../../../../assets/images/blackGraph.png";
import blackRejection from "../../../../assets/images/Rejection_Icon.png";
import blackClock from "../../../../assets/images/Clock_Icon.png";
import otSecurity from "../../../../assets/images/Operational_Technology_Icon_Large.png";
import riskIcon from "../../../../assets/images/Risk_Assessment_Icon_Large.png";
import systemIcon from "../../../../assets/images/System_Integrators_Icon_Large.png";
import incidentIcon from "../../../../assets/images/Incident_Response_Icon_Large.png";
import toolsIcon from "../../../../assets/images/Tools_Icon.png";

export const sectionData = {
  topic: "API Security",

  definition: `API security ensures that your APIs are protected from attacks, breaches, and misuse. Our
service focuses on safeguarding sensitive data, preventing unauthorized access, and maintaining
the reliability of your systems. With cutting-edge tools and proactive measures, we ensure your
APIs remain secure, compliant, and resilient against evolving cyber threats.`,

  importance: `
  API security is vital for safeguarding sensitive information and ensuring the reliability of digital
services. Effective API security prevents unauthorized access and ensures compliance with
regulations like GDPR and HIPAA, mitigating legal and financial risks. By prioritizing API security,
businesses not only protect their assets but also build customer trust and ensure smooth
operations in a complex digital landscape.`,

  keyPoints: [
    {
      title: `Authentication`,
      text: `Ensuring that only authorized users or systems can access the API. Common
methods include API keys, OAuth tokens, or JSON Web Tokens (JWT).`,
      icon: blackShield,
    },
    {
      title: `Authorization`,
      text: `Once authenticated, ensuring that users or systems can only access the
resources or actions they are permitted to. This is often done by validating access scopes and
permissions.`,
      icon: blackUser,
    },
    {
      title: `Rate Limiting`,
      text: `Protecting the API from abuse by limiting the number of requests a client can
make over a certain period of time, which helps prevent denial-of-service (DoS) attacks.`,
      icon: blackMeter,
    },
    {
      title: `Data Encryption`,
      text: `Encrypting sensitive data both in transit (via TLS/SSL) and at rest to protect it
from interception or breaches.`,
      icon: blackLock,
    },
    {
      title: `Input Validation`,
      text: `Checking incoming requests for malicious content, such as SQL injection or
cross-site scripting (XSS) attacks, and preventing invalid or unexpected data from causing harm.`,
      icon: blackLaptop,
    },
    {
      title: `Logging and Monitoring`,
      text: `Keeping track of API activity for identifying suspicious behavior,
detecting potential threats, and conducting audits.`,
      icon: blackBook,
    },
    {
      title: `API Gateway`,
      text: `Many organizations use an API gateway as an intermediary between clients and
the API. It can enforce security policies, authenticate requests, and monitor traffic.`,
      icon: blackGlobe,
    },
  ],

  stats: [
    {
      title: `API Breaches Are Common`,
      text: `Around 60% of organizations have experienced a data breach
caused by API exploitation in the last two years. This underscores the increasing importance of
securing APIs, which are now one of the largest attack surfaces for organizations​.`,
      icon: blackAlert,
    },
    {
      title: `Lack of Testing and Confidence`,
      text: `Only 40% of APIs are regularly tested for vulnerabilities. As a
result, organizations are confident in stopping just 26% of potential API attacks.​`,
      icon: blackTest,
    },
    {
      title: `Performance Impact`,
      text: `API breaches can lead to significant financial and operational losses. In
fact, 52% of respondents reported financial losses, while 50% mentioned brand value erosion​.`,
      icon: blackMeter,
    },
  ],

  subtopics: [
    {
      title: `API Data Privacy`,
      subtitle: `Secure Every Byte, Protect Data, Empower Trust`,
      text: `
Protect sensitive information in API transactions with robust data privacy solutions. Our API Data Privacy
service ensures that all personal and confidential data is encrypted and securely transmitted,
safeguarding your users and complying with global privacy regulations like GDPR and HIPAA..`,
    },
    {
      title: `API Governance`,
      subtitle: ` Consistency and Security at Every Call`,
      text: `
Ensure consistent and secure API management with our API Governance services. We help organizations
define clear policies and standards for API usage, security, and access control, ensuring that all APIs
adhere to organizational and industry guidelines while minimizing risk.`,
    },
    {
      title: `API Contract`,
      subtitle: `Align and Define, Clear Contracts, Seamless Integration`,
      text: `
Streamline collaboration between teams with API Contract management. This service ensures that API
providers and consumers are aligned through clear, structured contracts, defining request/response
expectations, data formats, and service agreements, reducing misunderstandings and integration errors.`,
    },
    {
      title: `API Testing`,
      subtitle: `Test, Trust, Triumph. Ensure Your APIs Are Ready for Anything`,
      text: `
Ensure the reliability and security of your APIs with comprehensive API Testing. Our service tests APIs for
functionality, performance, and security vulnerabilities, ensuring that your APIs are robust, efficient, and
able to withstand real-world usage.`,
    },
    {
      title: `API Performance`,
      subtitle: `Speed Meets Stability, Optimize Your API for Unmatched Efficiency`,
      text: `
Optimize your API’s speed and efficiency with our API Performance solutions. We monitor and enhance
the performance of your APIs, reducing latency and improving response times to ensure a seamless
experience for end-users, even under high traffic loads.`,
    },
    {
      title: `API Release`,
      subtitle: `Deploy with Confidence, Smooth Updates, Zero Disruptions`,
      text: `
Smoothly manage and deploy API updates with our API Release service. We help automate the process
of rolling out new API versions and features, ensuring minimal disruption to your users while
maintaining backward compatibility and optimal functionality.`,
    },
    {
      title: `API Version`,
      subtitle: `Seamless Evolution, Manage Versions, Embrace Innovation`,
      text: `
Manage multiple versions of your APIs effectively with our API Version management service. We provide
strategies to maintain older versions for legacy systems while introducing new, enhanced versions,
ensuring that all consumers have a smooth transition and access to the latest features.`,
    },
  ],

  pitchTitle: "Securing Your APIs, Protecting Your Business",

  pitch: `We specialize in comprehensive API security solutions designed to safeguard your business from
evolving threats. With services covering API Data Privacy, Governance, Contract Management, Testing,
Performance Optimization, and more, we provide end-to-end protection across the entire API lifecycle.
Our proactive approach ensures your APIs are secured with the latest technologies, tested rigorously,
and optimized for peak performance. Whether you’re preventing data breaches or maintaining high-
speed functionality, our solutions are tailored to meet your unique needs, ensuring both compliance and
operational efficiency. Choose us to stay ahead in API security and focus on innovation, not risk.`,

  conclusion: `Our comprehensive approach combines advanced threat detection, robust encryption, and proactive governance to protect your APIs from vulnerabilities and attacks. With our expertise, you can ensure compliance with global regulations, streamline integration processes, and maintain seamless performance, allowing you to focus on innovation and growth. Elevate your security posture and empower your business with solutions designed for today’s dynamic digital landscape`,

  videoCaptions: `In today's interconnected world, APIs serve as the backbone of modern digital services, driving
innovation and enabling seamless experiences. However, as APIs become essential for business, they
also expose organizations to significant threats, including cyberattacks, data breaches, and
unauthorized access. This makes API security more important than ever. We specialize in protecting
your APIs from every angle, ensuring that your data remains secure and your systems compliant. Our
comprehensive solutions—covering API Data Privacy, Governance, Testing, and Performance
Optimization—provide ultimate protection, allowing your APIs to function smoothly. By safeguarding
sensitive information and optimizing performance, we help you stay ahead of threats while you focus
on growing your business. Keep your APIs secure and your business thriving by choosing us for total
API protection. API Security—simplified, streamlined, and secured.`,

  backgroundInfo: {
    coloringInfo: {
      type: "gradient",
      data: {
        type: "linear",
        angle: 70,
        colorInfo: [
          { color: "rgba(255, 100,0,1)", stop: "0" },
          { color: "rgba(255,150,0,1)", stop: "15" },
          { color: "rgba(200,250,0, 1)", stop: "50" },
          { color: "rgba(150,255,0, 1)", stop: "75" },
          { color: "rgba(0,155,0, 1)", stop: "100" },
        ],
      },
    },
    patternInfo: null,
    imageInfo: null,
    floorInfo: null,
  },
  images: [iconImage, iconImage2],
  backgroundInfo2: apiGradient,
  heights: {
    desktop: {
      question: "250px",
      definition: "600px",
      question2: "250px",
      importance: "600px",
      question3: "200px",
      grid: "1500px",
      tabMenu: "1200px",
      list: "900px",
      conclusion: "750px",
      pitch: "900px",
    },
    mobile: {
      question: "250px",
      definition: "700px",
      question2: "250px",
      importance: "900px",
      question3: "150px",
      grid: "1700px",
      tabMenu: "900px",
      list: "900px",
      conclusion: "650px",
      pitch: "600px",
    },
  },
};
