import React from "react";
import { Text } from "./Text";

export const List = ({ type, contentData, fontSize }) => {
  const generateList = (type) => {
    const listItems = contentData.map((content) => {
      return (
        <li style={{ fontSize: "1.25cqw" }}>
          <Text
            continuous={false}
            text={content.text}
            textColor={"black"}
            stylizedText={[]}
            verticalAlignment={"bottom"}
            horizontalAlignment={"left"}
            backgroundInfo={{}}
            fontSize={fontSize}
            fontWeight={0}
            point={true}
          />
        </li>
      );
    });
    if (type === "ordered") {
      return <ol style={{margin: '0px', padding: '0px'}}>{listItems}</ol>;
    } else {
      return <ul style={{margin: '0px', padding: '0px'}}>{listItems}</ul>;
    }
  };
  return <div>{generateList(type)}</div>;
};
